@import "../../../../../theme/variables.scss";

.outerWrapper {
  display: flex;
  flex-direction: column;
  padding: 26px 30px;
  overflow: auto;

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 24px;
    text-align: center;

    > img {
      width: 150px;
      object-fit: contain;
      max-width: 90vw;
    }

    > div {
      width: 374px;
      max-width: 90vw;
      display: flex;
      flex-direction: column;
      gap: 8px;

      > span {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.09px;
        color: $PRIMARY_MAIN;
      }

      > p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.846%;
        color: $NEUTRAL_70;
      }
    }
  }

  .tooltip {
    display: flex;
    flex-direction: column;
    .quotaBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px 4px 0px 0px;
      background: var(--Primary-Main, #1571de);
      padding: 3px 8px;
      > span {
        color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 8px 8px 8px;
      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > div {
          display: flex;
          align-items: center;
          gap: 4px;
          > span {
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Caption 1/Reguler */
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
          .blueDark {
            width: 5px;
            height: 5px;
            border-radius: 50px;
            background-color: #1571de;
          }

          .blueSky {
            width: 5px;
            height: 5px;
            border-radius: 50px;
            background-color: #c1daff;
          }

          .grey {
            width: 5px;
            height: 5px;
            border-radius: 50px;
            background-color: #e0e0e0;
          }
        }

        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Caption 1/Reguler */
          // font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }

    .detail {
      padding: 4px 8px 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        color: var(--Primary-Main, #1571de);
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .types {
    display: flex;
    align-items: flex-end;
    height: 40px;

    > div {
      width: 180px;
      padding: 8px 10px;
      background-color: $PRIMARY_SURFACE;
      box-shadow: 2px 0px 2px 0px rgba(24, 108, 208, 0.25);
      border-radius: 8px 8px 0 0;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      &.active {
        background-color: $PRIMARY_MAIN;
        padding-block: 11px;

        > span {
          color: $NEUTRAL-10;
        }

        > div {
          color: $NEUTRAL-100 !important;
        }
      }

      > span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }

      > div {
        background-color: $NEUTRAL-10;
        display: grid;
        place-items: center;
        padding-inline: 6px;
        border-radius: 4px;
        height: 20px;

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;

        color: #5c9ce7;
      }
    }
  }

  .innerWrapper {
    padding: 16px;
    border-radius: 8px;
    background-color: $NEUTRAL-10;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    // height: calc(100vh - 200px);
    overflow: auto;

    .header {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      .hTop {
        display: grid;
        grid-template-columns: 1fr auto;

        > h3 {
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          letter-spacing: 0.12px;

          &.centered {
            text-align: center;
          }
        }

        > button {
          padding: 12px;
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
          border: none;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 8px;
          border-radius: 8px;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
          }
        }
      }

      .hBottom {
        display: grid;
        grid-template-columns: 356px auto;
        gap: 16px;

        > * {
          height: 40px;
        }

        .indicators {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 24px;

          .indicator {
            display: flex;
            align-items: center;
            gap: 8px;

            > div {
              height: 10px;
              width: 37px;
              border-radius: 8px;

              &.hired {
                background-color: $PRIMARY_MAIN;
              }

              &.vendor {
                background-color: $PRIMARY_DISABLED;
              }

              &.vacant {
                background-color: #e6e3e3;
              }
            }

            > p {
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: $NEUTRAL-80;

              > span {
                font-style: italic;
              }
            }
          }
        }
      }
    }

    .tableWrapper {
      width: 100%;
      border-radius: 8px;
      border: 1px solid $NEUTRAL-30;
      overflow: auto;

      .tableContainer {
        background-color: $NEUTRAL_LIGHTEST;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        overflow: auto;
        border-bottom: 0;
        min-height: 300px;
        &.noMinHeight {
          min-height: unset;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .table {
          border-collapse: separate;

          .firstHeadCell {
            border-right: 1px solid $NEUTRAL_40;
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            z-index: 1;
          }

          .oddRow {
            background-color: #f5f7fe !important;
          }

          .evenRow {
            background-color: $NEUTRAL_LIGHTEST !important;
          }

          .showOnMobile {
            display: none;
          }

          .tableRow {
            border: 0 !important;
            // cursor: pointer;

            &.cursor {
              cursor: pointer;
            }

            .tableCell {
              border-bottom: none !important;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #404040;
              .actionWrapper {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .dropdownTop {
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  background: var(--Color-Neutal-Neutral-10, #fff);

                  /* Shadow/700 */
                  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
                    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
                  min-width: 200px;

                  position: absolute;
                  right: 10px;
                  z-index: 2;
                  bottom: 30px;
                  > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 12px;
                    gap: 8px;
                    cursor: pointer;
                    > span {
                      color: var(--Primary-Main, #1571de);

                      /* Text M/Medium */
                      // font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px; /* 142.857% */
                    }
                  }
                  > div:hover {
                    background-color: #f5f7fe;
                    border-radius: 8px;
                    opacity: 1;
                  }
                }
                .dropDown {
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  background: var(--Color-Neutal-Neutral-10, #fff);

                  /* Shadow/700 */
                  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
                    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
                  min-width: 200px;

                  position: absolute;
                  right: 10px;
                  z-index: 2;
                  top: 30px;

                  > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 12px;
                    gap: 8px;
                    cursor: pointer;
                    > span {
                      color: var(--Primary-Main, #1571de);

                      /* Text M/Medium */
                      // font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px; /* 142.857% */
                    }
                  }
                  > div:hover {
                    background-color: #f5f7fe;
                    border-radius: 8px;
                    opacity: 1;
                  }
                }

                .actionBox {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 6px;
                  border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
                  background: var(--Color-Neutal-Neutral-10, #fff);
                  padding: 4px;
                  width: 24px;
                  cursor: pointer;
                  position: relative;
                }
              }

              &.firstCell {
                border-right: 1px solid $NEUTRAL_40;
                position: sticky;
                left: 0;
                background-color: inherit;
              }

              .link {
                display: flex !important;
                align-items: center !important;
                justify-content: flex-start;
                gap: 8px;

                > span:first-child {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 36px;
                  height: 36px;
                  background: $PRIMARY_SOFT;
                  border-radius: 50%;
                  flex-shrink: 0;
                }

                > div {
                  > span {
                    &:first-child {
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: $NEUTRAL_100;
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      line-clamp: 1;
                      -webkit-line-clamp: 1;

                      &:hover {
                        color: $PRIMARY_MAIN !important;
                      }
                    }

                    &:last-child {
                      font-family: Poppins;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 153.846%;
                    }
                  }
                }
              }

              .contacts {
                display: flex;
                flex-direction: column;

                > div {
                  display: flex;
                  align-items: center;
                  gap: 2px;
                }

                .duplicate {
                  &:hover {
                    color: $PRIMARY_MAIN !important;
                  }
                }
              }
            }
          }
        }
      }

      .paginationContainer {
        border-top: 1px solid $NEUTRAL_40 !important;
      }

      .addButtonWrapper {
        display: none;
      }

      .modalWrapper {
        position: fixed;
        transition: all 0.35s ease-in-out !important;
        z-index: 4;
        background-color: transparent;

        &.hideModal {
          bottom: -500px;
        }

        &.showModal {
          bottom: 0;
        }
      }

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: $NEUTRAL_OVERLAY;
        opacity: 0;
        z-index: 3;

        &.visible {
          right: 0;
          bottom: 0;
          opacity: 1;
          transition: opacity 0.4s;
        }
      }
    }
  }
}

.buttonStatusClient {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $NEUTRAL_40;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    text-align: center;
    color: var(--Neutral-50, #c2c2c2);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    &.active {
      cursor: not-allowed;
      color: var(--Primary-Main, #1571de);
      border-bottom: 2px solid #1571de;
    }
  }
}

@media screen and (max-width: 1040px) {
  .outerWrapper {
    padding: 18px;
    padding-bottom: 100px;

    .innerWrapper {
      .header {
        .hBottom {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .outerWrapper {
    padding: 18px;
    padding-bottom: 100px;

    .innerWrapper {
      // .header {

      //   .hBottom {
      //     grid-template-columns: 1fr;
      //   }
      // }

      .tableWrapper {
        // padding-top: 16px;
        // padding-inline: 18px;
        // padding-bottom: 60px;
        // height: calc(100vh - 230px);

        .table {
          min-width: 200px !important;
          max-width: 768px !important;
        }

        .firstHeadCell {
          border-right: 0 !important;
        }

        .firstCell {
          border-right: 0 !important;
          max-width: 50vw !important;
        }

        .hideOnMobile {
          display: none !important;
        }

        .showOnMobile {
          display: table-cell !important;

          button {
            width: 77px;
            height: 28px;
            background: #1571de;
            border-radius: 8px;
            border: none;
            color: $NEUTRAL_LIGHTEST;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .addButtonWrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 92px;
    background: $NEUTRAL_LIGHTEST;
    padding-top: 8px;
    padding-inline: 18px;
    display: flex;
    justify-content: center;
    display: block !important;
    z-index: 1;
    border-top: 1px solid $NEUTRAL-30;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-family: Poppins, sans-serif;
      height: 40px;
      width: 100%;
      background: $PRIMARY_MAIN;
      border: none;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $NEUTRAL_LIGHTEST;
    }
  }

  .modalWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $NEUTRAL_LIGHTEST;
    width: 100%;
    display: grid;
    place-items: center;
    z-index: 4;
  }
}
