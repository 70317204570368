@import "../../../theme/variables.scss";

.container {
  font-family: "Poppins";
  position: relative;
  width: 100%;
  background-color: $NEUTRAL_LIGHTEST;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  // justify-content: space-between;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;

  .disabled {
    outline: none !important;
    // border-color: $NEUTRAL_40;
    // border-width: 1px;
    // border-style: solid;
    // background-color: $NEUTRAL_30;
    color: $NEUTRAL_60;
    cursor: auto;
  }

  &.error {
    border-color: #f5610d;
  }
}

.valueText,
.placeholderText {
  flex-grow: 1;
}

.iconWrapper {
  display: grid;
  place-items: center;
  padding-right: 8px;
}

.containerColorDisabled {
  background-color: $NEUTRAL_30;
}

.activeContainer {
  transition: 0.2s;

  &:not(.error) {
    border-color: $PRIMARY_MAIN;
  }
}

.errorText {
  color: var(--Helper, #f5610d);
  /* Caption 1/Reguler */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.options {
  font-family: "Poppins";
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  background-color: $NEUTRAL_10;
  transition: 0.2s;
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
    0px 3px 5px rgba(9, 30, 66, 0.2);
  z-index: 2;

  &.optionsJobPost {
    max-height: 130px;
    overflow: auto;
  }

  &.overFlowOptions {
    max-height: 170px;
    overflow: auto;
  }
  &.topOptions {
    margin-top: 0px;
    bottom: 52px;
    border-radius: 8px 8px 0px 0px;
  }
}

.option {
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  color: $NEUTRAL_100;
  user-select: none;

  &.reset {
    font-style: italic;
    color: $NEUTRAL_60;
  }
}

.optionWrapper:hover {
  background-color: $NEUTRAL_20;
  cursor: pointer;
}

.borderBottom {
  margin: 0 10px;
  border-bottom: 1px solid $NEUTRAL_50;
}

.valueText {
  color: $NEUTRAL_100;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
}

.placeholderText {
  color: $NEUTRAL_60;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
}

.radioWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.radio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid $NEUTRAL_50;
}

.selectedRadio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid $PRIMARY_MAIN;
}
