@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  overflow: clip;
  width: 400px;

  .header {
    background-color: $DANGER_SURFACE;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px;

    >img {
      width: 143px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    >h3 {
      font-size: 16px;
      font-weight: 600;
      color: $DANGER_MAIN;
    }
  }

  .content {
    padding: 16px;

    >span {
      font-size: 14px;
    }

    .actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      padding-top: 36px;

      >button {
        padding: 12px 16px;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        font-family: Poppins, sans-serif;
        font-size: 14px;

        &:first-child {
          background-color: $NEUTRAL-10;
          color: $PRIMARY_MAIN;
        }

        &:nth-child(2) {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
        }
      }
    }
  }
}