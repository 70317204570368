.body {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    max-height: 100vh;
    min-height: 100vh;
    border-radius: 12px 0px 0px 12px;
    background: var(--Color-Neutal-Neutral-10, #fff);
    width: 400px;
    position: relative;
    animation: slideLeft 0.5s ease-out forwards; /* animasi slide ke kiri */
    transform: translateX(100%); /* awalnya sembunyikan ke kanan */
    overflow: auto;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      position: sticky;
      top: 0;
      border-bottom: 1px solid var(--Neutral-30, #dee2e7);
      background: var(--Color-Neutal-Neutral-10, #fff);
      padding: 16px;
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        cursor: pointer;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px;
      > span {
        color: #000;

        /* Text S/Medium */
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
      }

      .listBox {
        display: flex;
        flex-direction: column;
        gap: 12px;
        > div {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
          background: var(--Color-Neutal-Neutral-10, #fff);
          box-shadow: 1px 4px 15px 0px rgba(118, 118, 148, 0.1);
          cursor: pointer;
          .icon {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #1571de;
            border-radius: 50px;
          }

          > span {
            color: #000;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }
}

@keyframes slideLeft {
  to {
    transform: translateX(0); /* geser ke kiri sampai muncul */
  }
}

@media screen and (max-width: 450px) {
  .body {
    > div {
      width: 100%;
      border-radius: 0px;
    }
  }
}
