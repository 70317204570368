.bodyJobSeakerCard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-30, #ededed);
  background: var(--neutral-10, #fff);

  .titleJobs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;

    .addressBox {
      display: flex;
      align-items: center;
      gap: 4px;

      .addressText {
        color: var(--primary-main, #1571de);

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
      }
    }

    .titleBox {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .job {
        color: var(--neutral-60, #9e9e9e);

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
      }

      .title {
        color: #000;

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 22.4px */
      }
    }

    .salaryBox{
      display: flex;
      gap: 4px;
      align-items: center;
      
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      color: #404040;
    }
  }

  .buttonSection {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    background: var(--primary-main, #1571de);
    color: #fff;
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
  }

  .imagesBox {
    // width: 100%;
    // height: 100%;
    // width: 222px;
    width: 100%;
    aspect-ratio: 222/395;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      aspect-ratio: 222/395;
      max-width: 100%;
      // height: 395px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}

.emptyData {
  // flex-grow: 1;
  height: 100%;
  // min-height: 500px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;

  .boxEmptyText {
    display: flex;
    flex-direction: column;
    align-items: center;

    .descWarn {
      text-align: center;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #9e9e9e;
    }

    .warn {
      text-align: center;
      // font-family: Poppins;
      color: #000;
      font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.1px;
    }
  }
}

.loadingData {
  // flex-grow: 1;
  height: 50vh;
  // min-height: 500px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
}

.placement {
  display: flex;
  align-items: center;
  gap: 4px;
  > span {
    color: var(--primary-main, #1571de);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .slice {
    font-weight: 600;
  }
}

@media screen and (max-width: 1279px) {
  .bodyJobSeakerCard {
    gap: 10px;
  }

  // .card {
  //   // width: 32.5%;
  //   // width: 100%;
  // }
}

@media screen and (max-width: 350px) {
  .bodyJobSeakerCard {
    // gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }

  // .card {
  //   // width: 32.5%;
  //   // width: 100%;
  // }
}

// @media screen and (max-width: 879px) {
//   .card {
//     // width: 48%;
//   }
// }

// @media screen and (max-width: 650px) {
//   .card {
//     // width: 100%;
//   }
// }
