@import "../../../../../../theme/variables.scss";

.container {
  height: calc(100% - 70px);
  overflow-y: scroll;

  .headerWrapper {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .navigationWrapper {
    position: sticky;
    // top: 70px;
    z-index: 2;
  }

  .infoWrapper {
    background-color: #ffe5e5;
    padding: 6px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > span {
        color: $DANGER_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }
    }

    .buttonRight {
      display: flex;
      align-items: center;
      gap: 4px;
      border-radius: 6px;
      border: 1px solid var(--Primary-Main, #1571de);
      background: var(--Color-Neutal-Neutral-10, #fff);
      padding: 8px;
      cursor: pointer;

      > span:first-child {
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
      }
    }
  }

  .layoutWrapper {
    .errorWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 280px);
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .infoWrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
