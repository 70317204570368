@import "../../../theme/variables.scss";

.container {
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  overflow: clip;
  height: 100%;

  display: flex;
  flex-direction: column;
  // min-height: 400px;
  background-color: $NEUTRAL-10;

  &.error {
    border-color: #f5610d;
  }

  .defaultSkeleton {
    height: 24px;
    border-radius: 0;
    background-color: #eaecf2;
  }

  .dNone {
    display: none !important;
  }

  .emptyWrapper,
  .noSearchWrapper {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &.noSearchWrapper {
      display: grid;
      place-items: center;
      // overflow: hidden;
      padding: 24px;

      .card {
        max-width: 470px;
        background-color: $PRIMARY_SURFACE;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        gap: 16px;

        > img {
          width: 100%;
          object-fit: contain;
        }

        .desc {
          padding-top: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          text-align: center;

          > h3 {
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
          }

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL_60;
          }
        }
      }
    }
  }

  .showOnMobile {
    display: none;
  }

  &.v2 {
    .tableContainer {
      // flex-grow: 1;

      thead {
        position: sticky;
        top: 0;
        z-index: 1;

        tr {
          border-bottom: none !important;

          th {
            background-color: $BS_NEUTRAL_30;
          }
        }
      }

      tbody {
        tr {
          td {
            z-index: inherit;

            &.red {
              background-color: #f02c011a;
            }
          }
        }
      }
    }

    .paginationWrapper {
      position: sticky;
      bottom: 0;
      border-top: 1px solid $NEUTRAL_40;

      &.noSticky {
        position: static;
      }
    }
  }

  &.noBorder {
    border: none;
    border-radius: 0px;
  }

  .tableContainer {
    // flex-shrink: 0;
    overflow-x: auto;

    &.grow {
      flex-grow: 1;
    }

    &.noShrink {
      flex-shrink: 0;
    }

    table {
      min-width: 100%;
      border-collapse: separate;
      border-spacing: 0;

      tr {
        border-bottom: 1px solid $NEUTRAL_40;
      }

      .leftSeparator,
      .rightSeparator {
        height: 100%;
        width: 1px;
        position: absolute;
        background-color: $NEUTRAL_40;
        opacity: 0;

        &.forceShow {
          opacity: 1;
        }
      }

      .leftSeparator {
        left: 0;
        top: 0;
      }

      .rightSeparator {
        right: 0;
        top: 0;
      }

      thead {
        &.stickyTop {
          position: sticky;
          top: 0;
          // z-index: 1;
          z-index: 2;
        }

        tr {
          th {
            white-space: nowrap;
            padding: 16px 20px;
            background-color: $NEUTRAL_10;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 142.857% */
            border-bottom: 1px solid $NEUTRAL_40 !important;
            &.noPadding {
              padding: 0px !important;
              // padding-top: 16px;
              // padding-bottom: 16px;
              border-left: 1px solid #e0e0e0;
            }
            .totalClientLabel {
              display: flex;
              flex-direction: column;
              width: 100%;
              min-width: 135px;

              // gap: 16px;
              > span:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #e0e0e0;
                padding-bottom: 4px;
                padding-top: 4px;

                text-align: center;
              }

              > span {
                display: flex;
                align-items: center;
                width: 100%;

                > span {
                  color: var(
                    --Neutral-100,
                    var(--Color-Neutal-Neutral-19, #0a0a0a)
                  );
                  text-align: center;

                  /* Caption 2/Reguler */
                  // font-family: Poppins;
                  font-size: 11px;
                  font-style: italic;
                  font-weight: 400;
                  line-height: 16px; /* 145.455% */
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0;
                  padding-top: 8px;
                  padding-bottom: 8px;
                  border-right: 1px solid #e0e0e0;
                  width: 100%;
                  padding-left: 2px;
                  padding-right: 2px;
                  &.noBorder {
                    border: none;
                  }
                }
              }
            }

            &:first-child {
              position: relative;
            }
          }
        }
      }

      tbody {
        &.noRowBorder {
          tr,
          td {
            border-bottom: none;
          }
        }

        tr {
          td {
            padding: 16px 20px;
            background-color: $NEUTRAL_10;
            color: $NEUTRAL_90;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid $NEUTRAL_40;

            &.noPadding {
              padding: 0 !important;
              border-left: 1px solid #e0e0e0;
            }

            .totalClient {
              display: flex;
              align-items: center;
              // justify-content: space-between;
              width: 100%;
              min-width: 135px;

              > span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-right: 1px solid #e0e0e0;

                padding: 24px 0px;
                color: #000;
                // text-align: right;

                /* Text M/Bold */
                // font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 142.857% */
                &.noBorder {
                  border: none;
                }
              }
            }
            &.oddRow {
              background-color: #f5f7fe !important;
            }

            &.evenRow {
              background-color: $NEUTRAL_LIGHTEST !important;
            }
          }
        }
      }

      &.stickyLeft {
        thead,
        tbody {
          tr {
            > *:first-child {
              position: sticky;
              left: 0;

              .rightSeparator {
                opacity: 1;
              }
            }
          }
        }
      }

      &.stickyLeftRight {
        thead,
        tbody {
          tr {
            > *:first-child {
              position: sticky;
              left: 0;

              .rightSeparator {
                opacity: 1;
              }
            }

            > *:last-child {
              position: sticky;
              right: 0;

              .leftSeparator {
                opacity: 1;
              }

              .rightSeparator {
                // display: none;
                background-color: $NEUTRAL_10;
              }
            }
          }
        }
      }
    }
  }

  .tableContainer::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Safari, and other WebKit browsers */
  }

  .paginationWrapper {
    border-top: 1px solid $NEUTRAL_40;
  }
}

.borderDashboardRBM {
  border-right: 1px solid #e0e0e0;
}

@media screen and (max-width: 768px) {
  .container {
    // .stickyLeft {

    //   thead,
    //   tbody {

    //     tr {

    //       >*:first-child {
    //         position: static !important;
    //         // left: 0;
    //       }
    //     }
    //   }
    // }
    .stickyLeftRight {
      thead,
      tbody {
        tr {
          > *:last-child {
            position: static !important;
            // left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .hideOnMobile,
  .leftSeparator,
  .rightSeparator {
    display: none !important;
  }

  .showOnMobile {
    display: table-cell !important;
  }

  .container {
    border-radius: 0px;
  }
}
