@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 662px;
  max-width: 100vw;
  border-radius: 8px;

  .header {

    .top {
      display: flex;
      align-items: center;
      padding: 16px;
      padding-bottom: 8px;
      gap: 16px;

      >h3 {
        flex-grow: 1;
      }

      >button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }
    }

    .roles {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $NEUTRAL-30;
      overflow: auto;

      .role {
        font-size: 14px;
        white-space: nowrap;
        padding: 12px;
        color: $NEUTRAL-50;
        position: relative;
        cursor: pointer;

        &.active {
          color: #2E3192;
        }

        .border {
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: $PRIMARY_MAIN;
        }

      }

    }
  }

  .content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .searchWrapper {
      height: 44px;
    }

    .odd {

      td {
        background-color: $PRIMARY_SURFACE !important;
      }
    }


    .profile {
      display: flex;
      align-items: center;
      gap: 8px;

      >img {
        width: 32px;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: cover;
      }

      >span {
        font-size: 14px;
      }
    }

    .delete {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
}