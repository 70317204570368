@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 30px;
  border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: var(--Color-Neutal-Neutral-20, #f5f5f5);
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .searchBox {
      width: 356px;
      height: 44px;
    }

    .filter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-12px, 12px);
      gap: 4px;
      border-radius: 8px;
      background: var(--Primary-Main, #1571de);
      cursor: pointer;
      > span {
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  .middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .descBox {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 14px;
      flex-wrap: wrap;
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        &.blueDark {
          .dots {
            width: 20px;
            height: 20px;
            border-radius: var(--border-radius-04, 4px);
            background: var(--Primary-Main, #1571de);
          }
        }

        &.grey {
          .dots {
            width: 20px;
            height: 20px;
            border-radius: var(--border-radius-04, 4px);
            background: var(--Color-Neutal-Neutral-50, #c2c2c2);
          }
        }

        &.blueSky {
          .dots {
            width: 20px;
            height: 20px;
            border-radius: var(--border-radius-04, 4px);
            background: #94b2ff;
          }
        }

        &.white {
          .dots {
            width: 20px;
            height: 20px;
            border-radius: var(--border-radius-04, 4px);
            background: var(--Color-Neutal-Neutral-10, #fff);
          }
        }

        > span {
          color: #000;
          // font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .paging {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 14px;
      width: 50%;
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      > button {
        border-radius: 100px;
        border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
        background: var(--Color-Neutal-Neutral-10, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 8px;
      border-radius: 6px;
      border: 1px solid var(--Primary-Main, #1571de);
      background: var(--Primary-Surface, #f5f7fe);
      > span:first-child {
        color: var(--Neutral-70, #757575);

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
      > span:nth-child(2) {
        color: var(--Primary-Main, #1571de);

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
      .iconCross {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .top {
      flex-direction: column;
      align-items: flex-start;
      .searchBox {
        width: 100%;
      }
      .filter {
        width: 100%;
      }
    }

    .middle {
      flex-direction: column;
      align-items: flex-start;
      .paging {
        width: 100%;
        justify-content: flex-start;
      }
      .descBox {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 459px) {
  .container {
  }
}

@media screen and (max-width: 326px) {
  .container {
  }
}
