.placementContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  width: 620px;
  .header {
    padding: 16px 16px 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid #ededed;
    .left {
      width: 100%;
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Heading 6/SemiBold */
        font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.12px;
      }
    }

    .right {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .content {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    max-height: 400px;
    .status {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      .left {
        color: #000;

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      .right {
        border-radius: 9px;
        background: var(--Success-Surface, #eafef3);
        display: flex;
        padding: 6px 8px;

        align-items: center;
        gap: 8px;
        &.yellow {
          background: #fffdd9;
          border-radius: 9px;

          .dots {
            width: 12px;
            height: 12px;
            background-color: #e5dd1c;
            border-radius: 50px;
          }
        }

        &.green {
          border-radius: 9px;
          background: var(--Success-Surface, #eafef3);
          .dots {
            width: 12px;
            height: 12px;
            background-color: #27bb83;
            border-radius: 50px;
          }
        }

        &.grey {
          background-color: #f5f5f5;
          // background-color: #c2c2c2;
          border-radius: 9px;
          .dots {
            width: 12px;
            height: 12px;
            background-color: #c2c2c2;
            border-radius: 50px;
          }
        }

        &.red {
          background: var(--Danger-Surface, #faeff2);
          .dots {
            width: 12px;
            height: 12px;
            background-color: #ff3e13;
            border-radius: 50px;
          }
        }
        .dots {
          width: 12px;
          height: 12px;
          border-radius: 50px;
          background-color: #27bb83;
        }
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .tableBox {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.rowText {
  color: #000;

  /* Text S/Reguler */
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  // max-width: 300px;
  &.rowLocation {
    width: 250px;
  }
}
.dedicatedBoxRow {
  display: flex;
  justify-content: flex-end;
  > span {
    border-radius: var(--border-radius-04, 4px);
    background: var(--Primary-Soft, #e1edfe);
    display: flex;
    padding: 2px 8px;

    justify-content: center;
    align-items: center;
    color: var(--Primary-Main, #1571de);

    /* Caption 1/Medium */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    width: 100px;
  }
}

@media screen and (max-width: 400px) {
  .placementContainer {
    .content {
      .status {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }
}
