@import "../../../../../../theme/variables.scss";

.container {
  // display: grid;
  // grid-template-columns: 256px auto;
  display: flex;
  gap: 8px;
  max-width: 1440px;
  margin-inline: auto;
  overflow: clip;

  .index {
    border-radius: 8px;
    overflow: clip;
    // height: 482px;
    // display: flex;
    // flex-direction: column;
    // overflow: auto;
    width: 320px;
    background-color: $NEUTRAL-10;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 52px 1fr;

    .iHeader {
      background-color: $PRIMARY_MAIN;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 8px;
      height: 52px;
      // position: sticky;
      // top: 0;

      .iHLogo {
        background-color: $PRIMARY_SOFT;
        width: 32px;
        aspect-ratio: 1;
        flex-shrink: 0;
        border-radius: 50%;
        display: grid;
        place-items: center;
      }

      > span {
        font-size: 14px;
        font-weight: 700;
        color: $NEUTRAL-10;
      }
    }

    .iContent,
    .iEmpty {
      background-color: $NEUTRAL-10;
      flex-grow: 1;

      &.iEmpty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        > img {
          width: 183px;
          object-fit: contain;
        }

        > span {
          text-align: center;
          font-size: 14px;
          color: $NEUTRAL-60;
        }
      }

      &.iContent {
        display: flex;
        flex-direction: column;
        padding: 12px 8px;
        padding-top: 0;
        overflow: auto;
        // gap: 12px;

        .icHeader {
          display: flex;
          flex-direction: column;
          gap: 12px;
          position: sticky;
          top: 0;
          // top: 52px;
          background-color: $NEUTRAL-10;
          padding-block: 12px;

          > button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            background-color: $NEUTRAL-10;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid $PRIMARY_MAIN;
            color: $PRIMARY_MAIN;
          }

          .searchWrapper {
            height: 44px;
          }
        }

        .iList {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .each {
            padding: 8px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            transition: background-color ease 0.3s;

            &:hover {
              background-color: $PRIMARY_SURFACE;
              color: $PRIMARY_MAIN;
            }

            &.active {
              background-color: $PRIMARY_MAIN;
              color: $NEUTRAL-10;
            }

            > span {
              flex-grow: 1;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .main {
    background-color: $NEUTRAL-10;
    // height: 482px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    .noLocation {
      background-color: $NEUTRAL-10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      @include padding(24, 16, 16px);
      flex-grow: 1;
      text-align: center;

      > img {
        width: 220px;
        object-fit: contain;
        max-width: 90vw;
      }

      .desc {
        padding-top: 12px;
        @include padding-bottom(24, 16, 16px);
        display: flex;
        flex-direction: column;
        gap: 5px;
        max-width: 374px;

        > h3 {
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 27px */
          letter-spacing: 0.09px;
          color: $PRIMARY_MAIN;
        }

        > p {
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 153.846% */
          color: $NEUTRAL-70;
        }
      }

      > button {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: $NEUTRAL-10;
        padding: 12px;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        color: $PRIMARY_MAIN;

        > span {
          font-size: 14px;
        }
      }
    }

    .locationData {
      &.hidden {
        display: none !important;
      }

      > div {
        padding: 16px;
        display: grid;
        grid-template-columns: auto 1fr;

        &.ldHeader {
          display: flex;
          align-items: center;
          gap: 8px;
          border-bottom: 1px solid $NEUTRAL-30;
          position: sticky;
          top: 0;
          background-color: $NEUTRAL-10;
          z-index: 2;

          > h3 {
            flex-grow: 1;
            font-size: 20px;
            font-weight: 700;
            color: $PRIMARY_MAIN;
          }

          > button {
            background-color: transparent;
            display: grid;
            place-items: center;
            border: none;
            color: $DANGER_MAIN;
          }
        }

        &.ldMain {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
          gap: 8px;

          .role {
            background-color: $NEUTRAL-10;
            padding: 16px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            border: 1px solid $NEUTRAL-30;

            > span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              padding-bottom: 16px;
            }

            .rSearchWrapper {
              position: relative;
              padding-bottom: 8px;
              display: grid;
              grid-template-columns: 1fr;
              grid-template-rows: 1fr;
              height: 44px;

              > input {
                height: 44px;
                padding: 8px 12px;
                border: 1px solid $NEUTRAL-40;
                border-radius: 8px;
                color: #000;
                &.required {
                  border: 1px solid #ff3e13;
                }

                &:focus {
                  border-color: $PRIMARY_MAIN;
                  outline: none;
                }
              }

              .rSearchIcon {
                position: absolute;
                top: 8px;
                right: 12px;
                color: $NEUTRAL-60;
              }

              .rSearchOptions {
                position: absolute;
                background-color: $NEUTRAL-10;
                width: 100%;
                z-index: 1;
                top: calc(100% + 4px);
                padding-inline: 12px;
                box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
                  0px 3px 5px 0px rgba(9, 30, 66, 0.2);
                border-radius: 0px 0px 8px 8px;
                max-height: 350px;
                overflow: auto;

                .user {
                  padding-block: 10px;
                  border-bottom: 1px solid $NEUTRAL-40;
                  display: flex;
                  align-items: center;
                  gap: 16px;

                  > span {
                    flex-grow: 1;
                    cursor: pointer;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 171.429%;
                  }
                }
              }
            }

            .delegatedWrapper {
              padding-top: 8px;
              display: flex;
              flex-direction: column;
              gap: 8px;

              .delegated {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 6px 10px;
                border: 1px solid $NEUTRAL-40;
                border-radius: 8px;

                > span {
                  flex-grow: 1;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 142.857%;
                }

                .dAction {
                  display: flex;
                  align-items: center;
                  gap: 16px;

                  .picSetter {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    > span {
                      font-family: Poppins;
                      font-size: 11px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 145.455%;
                    }
                  }

                  > button {
                    display: grid;
                    place-items: center;
                    background-color: transparent;
                    border: none;
                  }
                }
              }
            }

            .empty {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              gap: 10px;
              padding-top: 8px;

              > img {
                width: 164px;
                object-fit: contain;
              }

              > span {
                font-size: 13px;
                color: $NEUTRAL-50;
                max-width: 250px;
              }
            }
          }
        }
      }
    }
  }
}

.deploymentBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .informationBox {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: #fffee4;
    padding: 12px;
    .informationOutline {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 8px;
      border-radius: 50px;
      background: #fa0;
    }

    .textBox {
      display: flex;
      flex-direction: column;
      gap: 2px;
      > span:first-child {
        color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

        /* Text M/Bold */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
      }

      > span:last-child {
        color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.buttonResponsive {
  position: fixed;
  left: 0;
  background-color: #000;
  z-index: 3;
  border-radius: 0px 8px 8px 0px;
  background: var(--Primary-Main, #1571de);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 7px 0px 6px;
  top: 200px;
  width: 30px;
  cursor: pointer;
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
    0px 3px 5px rgba(9, 30, 66, 0.2);
}

.required {
  color: #ff3e13;
}

@media screen and (max-width: 768px) {
  .container {
    .main {
      .locationData {
        > div {
          &.ldMain {
            display: flex;
            flex-direction: column;
            // grid-template-columns: unset;
          }
        }
      }
    }
  }
}
