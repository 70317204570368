.container {
  display: flex;
  flex-direction: column;

  .section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #ece6e6;
    padding-bottom: 24px;
    padding-top: 24px;
    .checkboxWrapper {
      background: #f7f7f7;
      border-radius: 8px;
      padding: 13px;
    }
    .desc {
      color: #000;
      //   font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    > span {
      color: #000;
      //   font-family: Poppins;
      font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .formSection {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      row-gap: 20px;
      column-gap: 15px;

      .floatingText {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #a3a3a3;
      }

      .stackedField {
        display: flex;
        flex-direction: column;
        gap: 11px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .section {
      .formSection {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
