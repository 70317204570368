@import "../../../../../theme/variables.scss";

.container {
  padding-block: 12px;
  padding-inline: 16px;
  padding-bottom: 90px;
  min-height: 100%;
  z-index: 0 !important;
  overflow: clip;

  .content {
    z-index: 0;
  }

  // .autoScroll {
  //   display: hidden;
  //   position: absolute;
  //   top: 0;
  // }

  .buttonsWrapper {
    padding-top: 16px;
    background: rgba(250, 250, 250, 0.98);
    border-top: 1px solid $NEUTRAL-40;
    position: fixed;
    padding: 16px 30px;
    height: 76px;
    bottom: 0;
    bottom: 0;
    right: 0;
    left: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .innerButtonsWrapper {
      max-width: 1440px;
      margin-inline: auto;
      display: flex;
      gap: 12px;
      justify-content: flex-end;
      flex-grow: 1;
    }

    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      border: none;
      font-family: "Poppins", sans-serif;
      display: flex;
      align-items: center;
      gap: 8px;

      &.previous {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &.next {
        background: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

.contractType {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: var(--Color-Neutal-Neutral-10, #fff);
  padding: 8px;
  margin-bottom: 16px;
  .icon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .desc {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span:first-child {
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      /* Text L/SemiBold */
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
    > span:last-child {
      color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    // padding-block: 16px;
    // padding-inline: 18px;
    .content {
      padding-bottom: 40px;
    }

    .buttonsWrapper {
      left: 0;
      &.height {
        height: 120px;
      }
      // max-height: 150px;
      .innerButtonsWrapper {
        flex-direction: column;
      }

      button {
        font-size: 12px;
      }
    }
  }
}
