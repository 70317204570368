@import "../../../../../theme/variables.scss";
$images_info: url("../../../../../assets/Images/Info-sign.png");

.Container {
  width: 100%;
  background: #f5f5f5;
  justify-content: center;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  overflow: auto;
  height: 100vh;

  .Wrapper {
    margin: auto;
    // width: 80vw;
    width: 100%;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0 !important;
    height: 100vh;
    overflow: auto;

    .Headers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      width: 83vw;

      .Logo {
        display: flex;
        gap: 16px;
        align-items: center;

        & > :first-child {
          background-color: #f3f5f7;
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          > img {
            width: 26px;
            height: 38px;
          }
        }

        & > :last-child {
          font-weight: 600;
          font-size: 16px;
        }
      }

      & > :last-child {
        > p {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    .informationBox {
      width: 83vw;
      display: flex;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      // background: var(--Primary-Surface, rgba(21, 113, 222, 0.1));
      padding: 12px;
      background-image: $images_info;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 16px;
      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 60px;
        background: var(--Primary-Disable, #b1d0f4);
      }

      > div:last-child {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
        > span:first-child {
          color: var(--Neutral-90, #404040);

          /* Text L/SemiBold */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        > span:last-child {
          color: var(--Neutral-90, #404040);

          /* Text L/Regular */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
    }

    .Body {
      width: 83vw;
      background: $NEUTRAL_10;
      display: grid;
      grid-template-columns: 35% 65%;
      border-radius: 12px;

      // flex-grow: 1;

      .LeftSection {
        border-right: 1px solid #ededed;

        .HeaderSection {
          border-bottom: 1px solid #ededed;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 8px 16px 8px 16px;
          &.headerExtend {
            padding-bottom: 0px;
          }
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            & > :first-child {
              font-weight: 600;
              font-size: 24px;
            }

            .ButtonWithIcon {
              padding: 10px 12px;
              display: flex;
              align-items: center;
              gap: 4px;
              border-radius: 8px;
              border: 1px solid $PRIMARY_MAIN;
              color: $PRIMARY_MAIN;
              cursor: pointer;
            }
          }

          .selectedButton {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            width: 100%;
            border-radius: 8px;
            background: var(--Primary-Surface, rgba(21, 113, 222, 0.1));
            padding: 4px;
            > div {
              padding: 8px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: var(--Neutral-90, #404040);
              text-align: center;

              /* Text M/Medium */
              // font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
              &.active {
                border-radius: 5px;
                background: var(--Primary-Main, #1571de);
                color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
              }
            }
          }

          .stepper {
            width: 100%;
            overflow: auto;
            padding-top: 8px;
          }
        }

        .SectionWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px;
          overflow: auto;
          height: calc(50vh - 16px);
          // overflow: auto;
          // max-height: 100vh;
          .SignContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;

            .SignWrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;
            }
          }

          .CanvasWrapper {
            height: 143px;
            border: 1px solid #ededed;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
              height: 60%;
              max-width: 70%;
            }
          }

          .Section {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .box {
              display: flex;
              flex-direction: column;
              gap: 8px;
              padding: 8px;
              border-radius: 8px;
              border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
              background: var(--Neutral-20, #fafafa);
              .location {
                display: flex;
                flex-direction: column;
                gap: 8px;
                > span {
                  color: var(--Neutral-100, #000);

                  /* Text M/Medium */
                  // font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
                }

                .Coords {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  gap: 8px;
                  > span {
                    // width: 100%;
                    color: var(--Neutral-100, #0a0a0a);

                    /* Text M/Regular */
                    // font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                  }
                }
              }

              > img {
                width: 100%;
              }
            }
          }
        }

        .SectionWrapper::-webkit-scrollbar {
          width: 8px; /* Lebar scrollbar */
          border-radius: 10px;
        }

        .SectionWrapper::-webkit-scrollbar-thumb {
          background-color: #c2c2c2;
          border-radius: 10px;
          /* Membuat thumb tampak lebih pendek */
        }
        .SectionWrapper::-webkit-scrollbar-thumb:hover {
          background-color: #a39e9e; /* Warna thumb saat di-hover */
        }

        .SectionWrapper::-webkit-scrollbar-track {
          background-color: #f1f1f1; /* Warna jalur scrollbar */
        }

        .SectionPersonalData {
          overflow: auto;
          height: calc(50vh - 16px);
          padding: 16px;

          .personalDataBox {
            display: flex;
            flex-direction: column;
            gap: 16px;

            > div {
              display: flex;
              flex-direction: column;
              gap: 10px;
              &.emptyHeader {
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(50vh - 50px);
                > span {
                  // flex-grow: 1;
                  color: #333;
                  text-align: center;

                  /* Heading 6/Bold */
                  // font-family: Poppins;
                  font-size: calc(
                    14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
                  );
                  font-style: normal;
                  font-weight: 700;
                  line-height: 140%; /* 33.6px */
                  letter-spacing: 0.12px;
                }
              }
              > span:first-child {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );

                /* Text M/Bold */
                // font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 142.857% */
              }
              > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                width: 100%;
                > span {
                  width: 100%;
                  &:first-child {
                    color: var(--Neutral-80, #616161);

                    /* Text S/Reguler */
                    // font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 153.846% */
                  }
                  &:last-child {
                    color: var(
                      --Neutral-100,
                      var(--Color-Neutal-Neutral-19, #0a0a0a)
                    );

                    /* Text S/Reguler */
                    // font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 153.846% */
                  }
                }
              }
            }
          }

          .documentBox {
            display: flex;
            flex-direction: column;
            gap: 20px;
            > div {
              display: flex;
              flex-direction: column;
              gap: 15px;
              > span {
                color: #000;
                // font-family: Poppins;
                font-size: calc(
                  14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
              > div {
                display: flex;
                flex-direction: column;
                gap: 4px;
                > div {
                  display: flex;
                  align-items: center;
                  gap: 16px;
                  padding: 10px;
                  border-radius: 8px;
                  border: 1px dashed #c5c5c5;
                  .imagesBox {
                    width: 50px;
                    height: 50px;
                    border-radius: 8px;
                    img {
                      width: 50px;
                      height: 50px;
                      object-fit: cover;
                      border-radius: 8px;
                    }
                  }

                  .imagesNull {
                    width: 50px;
                    height: 50px;
                    border-radius: 8px;
                    border-radius: 8px;
                    background: #e5f1ff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  .desc {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    > span:first-child {
                      color: #000;
                      font-family: Poppins;
                      font-size: calc(
                        14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
                      );
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                    }
                    > span:last-child {
                      color: #000;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      // max-width: 250px;
                      // width: 100%;
                      // overflow: hidden;
                      // text-overflow: ellipsis;
                      // white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
        }

        .SectionPersonalData::-webkit-scrollbar {
          width: 8px; /* Lebar scrollbar */
          border-radius: 10px;
        }

        .SectionPersonalData::-webkit-scrollbar-thumb {
          background-color: #c2c2c2;
          border-radius: 10px;
          /* Membuat thumb tampak lebih pendek */
        }
        .SectionPersonalData::-webkit-scrollbar-thumb:hover {
          background-color: #a39e9e; /* Warna thumb saat di-hover */
        }

        .SectionPersonalData::-webkit-scrollbar-track {
          background-color: #f1f1f1; /* Warna jalur scrollbar */
        }
      }

      .RightSection {
        padding: 16px;

        .PdfWrapper {
          width: 100%;
          height: 100%;

          > embed {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
    }

    .SectionTitle {
      font-weight: 600;
      font-size: 20px;
    }

    .Coords {
      font-weight: 400;
      font-size: 14px;
    }

    .ImagesPdfWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > img {
        width: 100%;
      }
    }

    .Action {
      width: 100%;

      > button {
        width: 100%;
      }
    }

    .ShowOnMobile {
      display: none;
    }
  }

  .actionBox {
    position: sticky;
    bottom: 0;
    background-color: $NEUTRAL-10;
    padding: 16px 32px;
    @include padding-inline(32, 16, 15px);
    width: 100vw;
    border: 1px solid $NEUTRAL-40;
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 3;

    > span:first-child {
      font-size: 12px;
      color: red;
    }
    .actionWrapper {
      display: flex;
      align-items: center;
      gap: 18px;

      > span {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $NEUTRAL_60;
      }
    }
  }
}

@media (width <=768px) {
  .Container {
    .HideOnMobile {
      display: none !important;
    }

    .ShowOnMobile {
      display: block !important;
    }

    .Wrapper {
      width: 100vw !important;
      padding: 16px;
      // padding-bottom: 100px;
      .Body {
        display: flex !important;
        flex-direction: column;
        background: #f5f5f5 !important;
        .LeftSection {
          .SectionPersonalData {
            height: 100%;
          }
          .SectionWrapper {
            height: 100%;
          }
        }
      }
    }

    .LeftSection {
      border: none !important;
    }

    .HeaderSection {
      border: none !important;
    }

    .Headers {
      flex-direction: column !important;
      align-items: flex-start !important;

      p {
        text-align: center !important;
      }
    }

    .RightSection {
      padding: 0 !important;
      margin-bottom: 24px;
      margin-top: 24px;
    }

    .LeftSection {
      padding: 0 !important;
    }

    .SectionWrapper {
      padding: 0 !important;
      gap: 24px !important;
    }

    .HeaderSection {
      padding: 16px 0 !important;
    }

    .SectionTitle {
      font-weight: 500 !important;
      font-size: 14px !important;
    }

    .HeaderTitle {
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }
}

@media (width <= 500px) {
  .Container {
    .Wrapper {
      .Body {
        .LeftSection {
          .SectionPersonalData {
            .personalDataBox {
              > div {
                > div {
                  flex-direction: column;
                  gap: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
