.modal {
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 443px;
  gap: 0px;
  &.modalGap {
    gap: 12px;
  }

  .imagesBox {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 150px;
      height: 100%;
    }
  }
  .descBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    > span:first-child {
      // display: ;
      color: #0f161f;
      text-align: center;

      /* Heading 6/SemiBold */
      font-family: Poppins;
      font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.12px;
      &.red {
        color: #ff3e13;
      }
    }
    > span:last-child {
      color: #878787;
      text-align: center;

      /* Text S/Reguler */
      //   font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      &.error {
        color: #ff3e13;
      }
      > span {
        color: var(--Neutral-100, #0a0a0a);

        /* Text S/Reguler */
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  .buttonSection {
    &.noMargin {
      margin-top: 0px;
    }
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    background: var(--Color-Neutal-Neutral-10, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    // padding: ;
    margin-top: 16px;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    border-radius: 8px 8px 0px 0px;
  }
}
