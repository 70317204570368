@import "../../../../theme/variables.scss";

.Body {
  width: 100vw;
  overflow: hidden;

  .container {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: $NEUTRAL_LIGHTEST;

    .errorWrapper {
      padding-top: 12px;
      color: $DANGER_MAIN;
      font-size: 13px;
      opacity: 0;

      &.error {
        opacity: 1;
      }
    }

    .redStar {
      color: $DANGER_MAIN;
      font-weight: 400;
      font-size: 11px;
    }

    .requiredLabel {
      color: $DANGER_MAIN;
      font-weight: 400;
      font-size: 13px;
      text-align: center;
    }

    .showOnMobile {
      display: none !important;
    }

    .fileUploadSection {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .boxDoc {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .error {
        color: $DANGER_MAIN;
        font-size: 13px;
      }
      .documentBox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border: 1px dashed $NEUTRAL_50;
        justify-content: center;
        align-items: center;
        padding: 22px 20px;
        background: $NEUTRAL_20;
        border-radius: 8px;

        .documentFile {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;

          > :first-child {
            color: $NEUTRAL_50;
          }

          > :last-child {
            font-weight: 500;
            font-size: 13px;
            text-align: center;
          }
        }

        .documentAction {
          display: flex;
          align-items: center;
          gap: 16px;
          align-items: center;

          > :first-child {
            cursor: pointer;

            > p {
              font-weight: 500;
              font-size: 14px;
              color: $DANGER_MAIN;
            }
          }

          > :last-child {
            cursor: pointer;

            > p {
              font-weight: 500;
              font-size: 14px;
              color: $PRIMARY_MAIN;
            }
          }
        }
      }

      .title {
        padding-bottom: 8px;

        > p {
          font-size: 14px;
          font-weight: 600;
          color: $NEUTRAL_100;
        }

        display: flex;
        align-items: flex-start;
        gap: 2px;
      }

      .box {
        margin-bottom: 8px;
      }

      .cardWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .cardBox {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .type {
          color: #000;

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .card {
        padding: 16px 18px;
        border: 1px solid $NEUTRAL_30;
        border-radius: 8px;
        display: flex;
        align-items: center;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          flex: 1;
        }

        button {
          display: grid;
          place-items: center;
          background-color: transparent;
          border: none;
        }
      }
    }

    .fileUploadWrapper {
      border-top: 1px solid $NEUTRAL_100;
      padding-top: 16px;

      > :first-child {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 16px;
      }
    }

    .bannerPosterWrapper {
      background: $PRIMARY_SURFACE;
      // border-radius: 16px;
      text-align: left;
      height: 100%;
      display: flex;
      flex-direction: column;

      .bannerHeader {
        display: flex;
        gap: 8px;
        align-items: center;
        background: $PRIMARY_MAIN;
        padding: 8px 16px;

        // border-radius: 16px 16px 0rem 0rem;
        .headerText {
          display: flex;
          flex-direction: column;
          gap: 2px;
          text-align: left;
          color: $NEUTRAL_10;

          > :first-child {
            font-weight: 400;
            font-size: 14px;
          }

          > :last-child {
            font-weight: 700;
            font-size: 16px;
          }
        }

        > img {
          width: 77px;
        }
      }

      .bannerContent {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow: auto;
        height: 100%;

        .sectionJob {
          padding: 16px;
          display: flex;
          flex-direction: column;
          background: $PRIMARY_SOFT;
          gap: 8px;
          text-align: left;
          border-radius: 8px;

          .jobType {
            display: flex;
            justify-content: space-between;

            > :first-child {
              font-weight: 400;
              font-size: 14px;
              color: $NEUTRAL_60;
            }

            > :last-child {
              font-weight: 500;
              font-size: 14px;
            }

            border-bottom: 1px solid $NEUTRAL_40;
            padding-bottom: 8px;
          }

          > :last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }

        .sectionDesc {
          > :first-child {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 4px;
          }

          > :last-child {
            font-weight: 400;
            font-size: 14px;
            white-space: pre-wrap;
          }

          .placementBox {
            display: flex;
            gap: 4px;
            justify-content: flex-start;
            align-items: center;

            .placementContent {
              background-color: $PRIMARY_SOFT;
              border-radius: 4px;
              padding: 4px 8px;

              font-size: 14px;
              font-weight: 400;
              color: $NEUTRAL_90;
            }
          }
        }

        .sectionRequirement {
          > :first-child {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 4px;
          }

          > ul {
            padding-left: 20px;

            > li {
              font-size: 14px;
              font-weight: 400;
              padding: 0;
            }
          }
        }
      }
    }

    > * {
      height: 100vh;
      overflow: auto;
    }

    .bannerSection {
      // overflow: hidden;
      position: fixed;
      width: 45%;
      background-image: linear-gradient(
          159.91deg,
          #1571de -1.6%,
          rgba(21, 113, 222, 0.57) 124.46%
        ),
        url(../../../../assets//Images/background-linear-gradient.png);
      background-size: cover;
      padding: 32px 32px 16px 32px;

      display: grid;
      grid-template-rows: 52px 1fr;
      gap: 16px;
      // place-items: center;

      .logoWrapper {
        display: flex;
        gap: 16px;
        // align-items: center;
        justify-content: space-between;
        // margin-bottom: 145px;
        .imagesLogoKerja365 {
          width: 95px;
          height: 41px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .logoRight {
          display: flex;
          align-items: center;
          gap: 8px;
          .textPermata {
            display: flex;
            flex-direction: column;
            // gap: 8px;
            .poweredBy {
              color: var(--Neutral-20, var(--Color-Neutal-Neutral-20, #f5f5f5));

              /* Caption 1/Reguler */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            .p {
              color: var(--Color-Neutal-Neutral-10, #fff);

              /* Text L/SemiBold */
              // font-family: Poppins;
              font-size: calc(
                14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 22.4px */
            }
          }
        }

        .logoIcon {
          background-color: $NEUTRAL_20;
          width: 46px;
          height: 46px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 20px;
          }
        }

        .logoText {
          font-weight: 500;
          font-size: 18px;
          color: $NEUTRAL_LIGHTEST;
        }
      }

      .bannerWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .bannerImageWrapper {
          width: 100%;
          height: 100%;
          position: relative;

          .imageSize {
            // display: inline-block;
            max-width: 100%;
            max-height: 100%;

            > img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .cardWrapper {
            // width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;

            .box {
              max-width: 100%;
              height: 100%;
              position: absolute;

              // left: 50%;
              // transform: translate(-50%, 0);
              > img {
                max-width: 100%;
                height: 100%;
                opacity: 0;
                min-width: 300px;
              }
            }
          }

          .flipCard {
            background-color: transparent;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            // border: 1px solid #f1f1f1;
            perspective: 1000px;
            /* Remove this if you don't want the 3D effect */
          }

          .flipCardInner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;
          }

          .flipCard:hover .flipCardInner {
            transform: rotateY(180deg);
          }

          /* Position the front and back side */
          .flipCardFront,
          .flipCardBack {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            /* Safari */
            backface-visibility: hidden;
          }

          .flipCardFront {
            > img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .flipCardBack {
            transform: rotateY(180deg);
            width: 100%;
            // background-color: $PRIMARY_SURFACE;
          }
        }

        .bannerTextWrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 0 10%;
          color: $NEUTRAL_LIGHTEST;
          text-align: center;

          & > :first-child {
            font-weight: 600;
            font-size: 20px;
          }

          & > :last-child {
            font-weight: 400;
            font-size: 14px;
          }
        }

        .bannerInformation {
          display: flex;
          border-radius: 16px;
          background: $PRIMARY_MAIN;
          justify-content: space-between;
          padding: 12px 16px;
          gap: 30px;

          > :first-child {
            display: flex;
            flex-direction: column;
            color: $NEUTRAL_10;

            > :first-child {
              font-weight: 700;
              font-size: 14px;
            }

            > :last-child {
              font-weight: 400;
              font-size: 11px;
            }
          }

          > :last-child {
            display: flex;
            gap: 8px;
            align-items: center;
          }

          .downloadIcon {
            background: $NEUTRAL_100;
            border-radius: 12px;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            cursor: pointer;

            > img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    .contentSection {
      margin-left: 45%;
      width: 55%;

      .errorBannerContainer {
        margin-bottom: 24px;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background-color: $DANGER_SURFACE;

        > span {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          cursor: pointer;
          color: $DANGER_MAIN;
        }
      }

      .boxInputForm {
        padding: 4px 80px 80px 80px;
      }

      .contentContainer {
        .checkboxWrapper {
          padding-top: 16px;

          .checkbox {
            display: flex;
            align-items: center;
            gap: 8px;

            > input {
              width: 20px;
              height: 20px;
            }

            > p {
              font-weight: 400;
              font-size: 14px;

              > span {
                cursor: pointer;
                color: $PRIMARY_MAIN;
                text-decoration: underline;
              }
            }
          }
        }

        .headWrapper {
          width: 100%;
          border-bottom: 1px solid #ece6e6;
          padding-bottom: 4px;
          margin-bottom: 20px;

          .titleHead {
            // padding-bottom: 8px;
            // padding: 16px 76px 32px 76px;

            .descText {
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding: 32px 80px 16px 80px;
              .descForm {
                color: var(
                  --Neutral-60,
                  var(--Color-Neutal-Neutral-60, #9e9e9e)
                );

                /* Text M/Reguler */
                // font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
              }
              .H1 {
                color: #000;

                /* Heading 5/SemiBold */
                // font-family: Poppins;
                font-size: calc(
                  16px + (26 - 16) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 36.4px */
                letter-spacing: 0.13px;
              }
            }

            .infoLocation {
              display: flex;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
              background: var(--Primary-Soft, #e1edfe);
              padding: 10px;
              margin: 0px 80px 16px 80px;

              > div:first-child {
                width: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              > span:nth-child(2) {
                color: var(--Primary-Main, #1571de);

                /* Caption 1/Reguler */
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
                min-width: 110px;
              }
              > span:nth-child(3) {
                color: var(--Primary-Main, #1571de);

                /* Caption 1/Reguler */
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
              }
              > span:last-child {
                color: var(--Primary-Main, #1571de);

                /* Caption 1/Reguler */
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px; /* 133.333% */
                width: 100%;
              }
            }
          }

          .jobWrapper {
            display: flex;
            background-color: $NEUTRAL_20;
            padding: 13px 14px;
            border-radius: 8px;
            justify-content: space-between;
            align-items: center;

            .jobTitle {
              display: flex;
              align-items: center;
              gap: 10px;

              & > :first-child {
                color: $NEUTRAL_100;
              }

              & > :last-child {
                color: $NEUTRAL_100;
                font-weight: 600;
                font-size: 16px;
              }
            }

            & > :last-child {
              font-weight: 400;
              font-size: 14px;
              color: $PRIMARY_MAIN;
              cursor: pointer;
            }
          }
        }

        .inputWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .detailsWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease;

            &.expanded {
              max-height: 1000px;
            }

            > span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: $NEUTRAL_70;
            }

            > div {
              padding: 16px;
              border-radius: 8px;
              background-color: $NEUTRAL_20;
              border: 1px solid $NEUTRAL_40;

              > p {
                display: flex;
                gap: 8px;

                > span {
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;

                  &:first-child {
                    padding-right: 8px;
                  }
                }
              }
            }
          }

          .selectContainer {
            > :first-child {
              display: flex;
              gap: 2px;
              margin-bottom: 8px;

              > :first-child {
                font-weight: 500;
                font-size: 13px;
              }

              > :last-child {
                font-weight: 400;
                font-size: 11px;
                color: $DANGER_MAIN;
              }
            }

            .selectWrapper {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 8px;

              .select {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border: 1px solid $NEUTRAL_40;
                background: $NEUTRAL_20;
                border-radius: 8px;

                > p {
                  font-weight: 400;
                  font-size: 14px;
                  color: $NEUTRAL_80;
                }
              }

              .selected {
                border: 1px solid $PRIMARY_MAIN;
                background: $PRIMARY_SURFACE;

                > p {
                  color: $PRIMARY_MAIN;
                }
              }
            }
          }

          .inputEndAdornment {
            position: relative;

            .endAdornment {
              position: absolute;
              right: 10px;
              top: 35px;
              background-color: transparent;
              border: none;
              color: $NEUTRAL_100;
              display: grid;
              place-items: center;
              // z-index: 0;
            }
          }

          .inputDateWrapper {
            position: relative;

            .title {
              padding-bottom: 8px;

              > p {
                font-size: 13px;
                font-weight: 600;
                color: $NEUTRAL_100;
              }

              display: flex;
              align-items: flex-start;
              gap: 2px;
            }

            > input {
              border: 1px solid $NEUTRAL_40;
              border-radius: 8px;
              padding-inline: 12px;
              height: 44px;
              width: 100%;
              background-color: transparent;

              &[type="time"]::-webkit-calendar-picker-indicator {
                opacity: 0;
              }

              &:focus {
                outline: none;
                border-color: $PRIMARY_MAIN;
              }

              &:disabled {
                background-color: $NEUTRAL_40;
              }

              &.error {
                border-color: #f5610d;
              }
            }

            .placeholderReplacement,
            ::placeholder,
            ::-webkit-input-placeholder {
              position: absolute;
              top: 12px;
              left: 12px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_70;
              opacity: 1;
            }

            .endAdornment {
              position: absolute;
              right: 8px;
              top: 40px;
              background-color: transparent;
              border: none;
              color: $NEUTRAL_100;
              display: grid;
              place-items: center;
              // z-index: 0;
            }
          }

          .errorText {
            color: var(--Helper, #f5610d);
            /* Caption 1/Reguler */
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
          }
        }

        .imageInput {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;

          .error {
            margin-top: 12px;
            color: $DANGER_MAIN;
            font-size: 13px;
          }

          .Section {
            width: 282px;
            height: 244px;

            .ImageidContainer {
              position: relative;
              border-radius: 8px;

              height: 100%;

              & > img {
                height: 100%;
                width: 100%;
                border-radius: 8px;
                object-fit: contain;
              }

              .Footer {
                position: absolute;
                bottom: 0;
                border-radius: 8px;
                display: flex;
                color: $NEUTRAL_10;
                width: 100%;
                height: 30%;
                align-items: center;
                justify-content: center;
                background: linear-gradient(
                  0deg,
                  #000 0%,
                  rgba(0, 0, 0, 0) 100%
                );
              }
            }

            .DropdownSection {
              border: 2px dashed $PRIMARY_MAIN;
              height: 100%;
              border-radius: 8px;
              background-color: $NEUTRAL_20;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 8px;
              width: 100%;
              &.onPointer {
                cursor: pointer;
              }

              > :first-child {
                color: $PRIMARY_MAIN;
              }
            }

            .disabledOnDrop {
              border: 2px dashed $PRIMARY_DISABLED !important;
              color: $PRIMARY_DISABLED !important;

              .label {
                color: $NEUTRAL_60;
              }
            }
          }
        }

        .info {
          margin-bottom: 24px;
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 4px;
          border-radius: 8px;
          background-color: $PRIMARY_SOFT;

          > span {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }

        .buttonWrapper {
          margin-top: 24px;
          display: flex;
          justify-content: flex-end;

          .button {
            width: 120px;
            height: 36px;
          }

          .accordionButton {
            display: flex;
            gap: 16px;

            & > :first-child {
              width: 138px;
              height: 36px;
            }

            & > :last-child {
              width: 138px;
              height: 36px;
            }
          }
        }

        .accordionWrapper {
          padding-bottom: 32px;
          border-bottom: 1px solid $NEUTRAL_70;

          .accordionTitle {
            font-weight: 600;
            font-size: 20px;
            color: $NEUTRAL_100;
            margin-bottom: 30px;
          }

          .accordionList {
            border-top: 1px solid $NEUTRAL_40;
            margin-top: 16px;
            height: 50vh;
            overflow: auto;

            .accordion {
              margin-top: 16px;
              border-bottom: 1px solid $NEUTRAL_40;
              padding-bottom: 16px;
            }

            & > :last-child {
              border: none;
            }
          }
        }
      }
    }
  }

  .CarouselItem {
    display: flex !important;
    justify-content: center;
    width: 100% !important;
    height: 70vh;

    // border-radius: 16px;
    > img {
      width: 400px;
      height: 70vh;
      // border-radius: 16px;
      object-fit: cover;
    }

    > div {
      width: 400px;
      height: 70vh !important;
    }
  }

  .assistButton {
    position: fixed;
    bottom: 24px;
    right: 24px;
    display: flex;
    gap: 8px;
    padding: 0px 12px;
    border-radius: 24px;
    background: $PRIMARY_MAIN;
    height: 40px;
    align-items: center;
    cursor: pointer;

    > img {
      width: 24px;
      height: 24px;
    }

    > p {
      color: $NEUTRAL_10;
    }
  }

  .notFoundContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;

    // > img {
    //   width: 338px;
    //   height: 213px;
    // }

    .textWrapper {
      text-align: center;

      > :first-child {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 8px;
      }

      > :last-child {
        font-weight: 400;
        font-size: 14px;
        color: $NEUTRAL_70;
      }
    }

    .cardWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 8px;
      gap: 8px;

      .card {
        width: 327px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 8px;
        gap: 16px;
        box-shadow: 0px 0px 1px 0px #091e424f;
        box-shadow: 0px 3px 5px 0px #091e4233;

        .buttonWrapper {
          display: flex;
          height: 36px;
          align-items: stretch;
          justify-content: center;
        }

        .content {
          display: flex;
          gap: 8px;

          > img {
            width: 37px;
            height: 66px;
          }

          .informationWrapper {
            gap: 4px;

            .subTitle {
              color: $NEUTRAL_60;
              font-weight: 400;
              font-size: 12px;
            }

            .title {
              color: $NEUTRAL_100;
              font-weight: 600;
              font-size: 16px;
            }

            .placementWrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;

              > :first-child {
                min-width: 16px;
              }

              .textPlacement {
                color: $PRIMARY_MAIN;
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .exploreWrapper {
      display: flex;
      font-family: "Poppins";
      text-align: center;
      color: $PRIMARY_MAIN;
      font-weight: 400;
      size: 14px;
      gap: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Body {
    .container {
      width: 100vw;
      height: 100vh;
      display: grid;
      background-color: $NEUTRAL_LIGHTEST;
      // flex-direction: column !important;
      overflow-x: visible;
      grid-template-columns: 1fr 1fr;
      transition: 1s;

      .showOnMobile {
        display: block !important;
      }

      .fileUploadSection {
        .title {
          padding-bottom: 4px;
          font-size: 13px;
          font-weight: 600;
          color: $NEUTRAL_100;
        }

        .box {
          margin-bottom: 8px;
        }

        .card {
          padding: 12px 16px;
          border: 1px solid $NEUTRAL_30;
          border-radius: 8px;
          display: flex;
          align-items: center;

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            flex: 1;
          }

          button {
            display: grid;
            place-items: center;
            background-color: transparent;
            border: none;
          }
        }
      }

      > * {
        height: auto;
        overflow: auto;
      }

      .bannerSection {
        // overflow: hidden;
        width: 100vw !important;
        position: relative;
        min-height: 35vh;
        width: 100%;
        background-image: linear-gradient(
            159.91deg,
            #1571de -1.6%,
            rgba(21, 113, 222, 0.57) 124.46%
          ),
          url(../../../../assets//Images/background-linear-gradient.png);
        background-size: cover;
        padding: 6%;

        .logoWrapper {
          display: flex;
          gap: 16px;
          align-items: center;
          // margin-bottom: 145px;
          margin-bottom: 20px;
          position: absolute;
          top: 16px;
          left: 5%;

          .logoIcon {
            background-color: $NEUTRAL_20;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            > img {
              width: 40%;
            }
          }

          .logoText {
            font-weight: 500;
            font-size: 14px;
            color: $NEUTRAL_LIGHTEST;
          }
        }

        .bannerWrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 5%;

          .bannerImageWrapper {
            width: 40%;

            > img {
              width: 100%;
            }
          }

          .bannerTextWrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0;
            color: $NEUTRAL_LIGHTEST;
            text-align: center;
            width: 60%;

            & > :first-child {
              font-weight: 700;
              font-size: 16px;
            }

            & > :last-child {
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }

      .contentSection {
        margin-left: 0;
        // width: 100%;
        width: 100vw !important;
        .boxInputForm {
          padding: 0px 32px 32px 32px;
        }
        .contentContainer {
          // padding: 32px 5%;

          .headWrapper {
            width: 100%;
            border-bottom: none !important;
            padding-bottom: 4px;
            margin-bottom: 20px;

            .titleHead {
              padding: 32px 32px 0px 32px;
              // padding-bottom: 8px;
              .descText {
                display: none;
              }

              .infoLocation {
                margin: 0px;
                margin-top: 20px;
              }

              .backArrow {
                display: flex !important;
                gap: 8px;
                color: $PRIMARY_MAIN;
              }

              > p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                padding-bottom: 4px;
              }

              > span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                /* identical to box height, or 133% */

                color: #6f6f70;
              }
            }

            .jobWrapper {
              display: none;
              background-color: $NEUTRAL_20;
              padding: 13px 14px;
              border-radius: 8px;
              justify-content: space-between;
              align-items: center;

              &.flex {
                display: flex;
              }

              .jobTitle {
                display: flex;
                align-items: center;
                gap: 10px;

                & > :first-child {
                  color: $NEUTRAL_100;
                }

                & > :last-child {
                  color: $NEUTRAL_100;
                  font-weight: 600;
                  font-size: 16px;
                }
              }

              & > :last-child {
                font-weight: 400;
                font-size: 14px;
                color: $PRIMARY_MAIN;
                cursor: pointer;
              }
            }
          }

          .inputWrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          .buttonWrapper {
            margin-top: 24px;
            display: flex;
            justify-content: flex-end;

            .button {
              width: 100%;
              height: 36px;
            }

            .accordionButton {
              display: flex;
              gap: 16px;
              width: 100%;

              & > :first-child {
                width: 100%;
                height: 36px;
              }

              & > :last-child {
                width: 100%;
                height: 36px;
              }
            }
          }

          .accordionWrapper {
            padding-bottom: 32px;
            border-bottom: 1px solid $NEUTRAL_70;

            .accordionTitle {
              font-weight: 600;
              font-size: 20px;
              color: $NEUTRAL_100;
              margin-bottom: 30px;
            }

            .accordionList {
              .accordion {
                margin-top: 16px;
                border-bottom: 1px solid $NEUTRAL_40;
                padding-bottom: 16px;
              }

              & > :last-child {
                border: none;
              }
            }
          }
        }
      }

      .bannerSectionMobile {
        padding-top: 19px;
        padding-inline: 16px;
        width: 100vw;
        height: 100vh;
        padding-bottom: 24px;
        display: flex !important;
        flex-direction: column;
        gap: 24px;

        .bannerLogo {
          display: flex;
          gap: 16px;
          align-items: center;
          padding-bottom: 16px;

          .logoIcon {
            background: #f3f5f7;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;

            > img {
              height: 25px;
            }
          }

          .logoText {
            font-weight: 500;
            font-size: 14px;
            color: $NEUTRAL_90;
          }
        }

        .InformationBox {
          display: flex;
          align-items: center;
          gap: 8px;

          .informationButton {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            border-radius: 8px;
            background: var(--neutral-100, #0a0a0a);
            padding: 8px;
            width: 100%;

            img {
              width: 24px;
              height: 24px;
            }

            .titleDownload {
              color: var(--neutral-10, #fff);

              /* Text-M/SemiBold */
              // font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              /* 142.857% */
            }
          }
        }

        .bannerContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          // grid-template-columns: 1fr;
          // grid-template-rows: 1fr 50px;
          justify-content: space-between;
        }

        .bannerAction {
          // height: ;
          display: flex;
          flex-direction: column;
          gap: 12px;

          > :last-child {
            > div {
              padding: 12px;
            }
          }

          .bannerInformationMobile {
            display: flex;
            border-radius: 16px;
            background: $PRIMARY_MAIN;
            justify-content: space-between;
            padding: 8px 16px;

            > :first-child {
              display: flex;
              flex-direction: column;

              > p {
                font-weight: 500;
                font-size: calc(
                  10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
                );
                color: $NEUTRAL_10;
              }
            }

            > :last-child {
              display: flex;
              align-items: center;
              gap: 8px;
            }

            .downloadIcon {
              background: $NEUTRAL_100;
              border-radius: 12px;
              padding: 8px 12px;
              display: flex;
              align-items: center;
              justify-content: center;

              > img {
                width: 24px;
                height: 24px;
              }
            }
          }

          > :first-child {
            padding-block: 12px;
          }
        }
      }
    }

    .ShowForm {
      transform: translateX(-100vw);
    }

    .hideOnMobile {
      display: none !important;
    }

    .notFoundContainer {
      height: 100% !important;
      padding: 16px;

      // > img {
      //   width: 284px;
      //   height: 237px;
      // }

      .textWrapper {
        > :first-child {
          margin-bottom: 4px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Body {
    .container {
      .bannerSectionMobile {
        .bannerAction {
          .bannerInformationMobile {
            .downloadIcon {
              background: $NEUTRAL_100;
              border-radius: 12px;
              padding: 8px 12px;
              display: flex;
              align-items: center;
              justify-content: center;

              > img {
                width: 15px;
                height: 15px;
              }
            }
          }

          > :first-child {
            padding-block: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .Body {
    .container {
      .bannerSection {
        .logoWrapper {
          .logoIcon {
            width: 35px;
            height: 35px;
            > img {
              width: 15px;
            }
          }
          .logoRight {
            .textPermata {
              .p {
                font-size: 14px;
              }
            }
          }
          .imagesLogoKerja365 {
            width: 60px;
            // height: 50px;
          }
        }
      }
    }
    .notFoundContainer {
      .cardWrapper {
        flex-direction: column;
      }
    }
  }
}
