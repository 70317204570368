@import "../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  max-width: 1440px;
  margin-inline: auto;

  // z-index: 0;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .buttonWrapper {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding-top: 16px;

    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      border: none;

      &:first-child {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &:last-child {
        background: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .buttonWrapper {
      padding-top: 8px;

      > * {
        width: 100%;
      }
    }
  }
}
