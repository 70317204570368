@import "../../../../../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;

  .container {
    position: relative;
    border-radius: 8px;
    background-color: $NEUTRAL_LIGHTEST;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 440px;
    max-height: calc(100vh - 90px);
    overflow-y: scroll;

    .header {
      position: sticky;
      top: 0;
      background-color: inherit;
      padding-block: 16px;
      padding-inline: 16px;
      z-index: 1;

      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: $PRIMARY_MAIN;
        padding-bottom: 16px;
      }

      .searchbarWrapper {
        height: 36px !important;
      }
    }

    .content {
      padding-inline: 16px;
      flex-grow: 1;
      z-index: 0;

      .each {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 10px;
        border-bottom: 1px solid $NEUTRAL_50;

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $NEUTRAL_100;
        }

        .unchecked {
          color: $NEUTRAL_50 !important;
        }

        .checked {
          color: $PRIMARY_MAIN !important;
        }
      }

      .noOption {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $NEUTRAL_50;
        padding-block: 10px;
      }
    }

    .actionsWrapper {
      position: sticky;
      bottom: 0;
      background-color: inherit;
      padding: 16px;
      box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.14);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      button {
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        &:first-child {
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
          background-color: $NEUTRAL_10;
        }

        &:last-child {
          border: 1px solid $PRIMARY_MAIN;
          color: $NEUTRAL_10;
          background-color: $PRIMARY_MAIN;
        }
      }
    }
  }
}

.mobileContainer {
  background-color: $NEUTRAL_LIGHTEST;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding-inline: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  overflow-y: scroll;
  z-index: 0;

  > * {
    width: 100%;
  }

  .topWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: inherit;
    padding-top: 15px;
  }

  .topLine {
    background-color: #b4b7ba;
    height: 5px !important;
    width: 42px;
    border-radius: 5px;
  }

  .header {
    padding-top: 17px;
    text-align: center;
    padding-bottom: 20px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_DARKEST;
    }
  }

  .content {
    flex-grow: 1;
    z-index: 0;

    .each {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-block: 10px;
      border-bottom: 1px solid $NEUTRAL_50;

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $NEUTRAL_100;
      }

      .unchecked {
        color: $NEUTRAL_50 !important;
      }

      .checked {
        color: $PRIMARY_MAIN !important;
      }
    }

    .noOption {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $NEUTRAL_50;
      padding-block: 10px;
    }
  }

  .actionsWrapper {
    position: sticky;
    bottom: 0;
    background-color: inherit;
    padding-block: 24px;
    // box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.14);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    button {
      padding: 8px 16px;
      border-radius: 8px;
      cursor: pointer;
      width: 100%;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &:first-child {
        border: 1px solid $PRIMARY_MAIN;
        color: $PRIMARY_MAIN;
        background-color: $NEUTRAL_10;
      }

      &:last-child {
        border: 1px solid $PRIMARY_MAIN;
        color: $NEUTRAL_10;
        background-color: $PRIMARY_MAIN;
      }
    }
  }
}

.v2Container {
  background-color: $NEUTRAL-10;
  width: 600px;
  max-width: 100vw;
  border-radius: 8px;
  height: 637px;
  max-height: 85vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  > * {
    padding: 16px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 12px;

    > h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 22.4px */
      color: $PRIMARY_MAIN;
    }

    .searchWrapper {
      height: 44px;
    }
  }

  .content {
    overflow: auto;
    padding-top: 0;

    .cityBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding-block: 12px;
      border-bottom: 1px solid $NEUTRAL-40;
      .descBox {
        width: 50%;
        min-width: 135px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .disabledDesc {
          color: var(--Neutral-70, #757575);

          /* Text M/Reguler */
          // font-family: Poppins;
          padding: 4px 9px;
          border-radius: 31px;
          background: var(--Color-Neutal-Neutral-30, #ededed);
          font-size: calc(11px + (12 - 11) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          text-align: center;
        }
      }

      .city {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;

        > span {
          font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          // line-height: 24px;
          // flex-grow: 1;
          cursor: pointer;
          /* 171.429% */
          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.14);

    > button {
      padding: 12px 16px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;

      &:first-child {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;
        border: 1px solid $PRIMARY_MAIN;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
        border: 1px solid $PRIMARY_MAIN;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .v2Container {
    .actions {
      padding-bottom: 45px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}
