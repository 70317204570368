@import "../../../../../../theme/variables.scss";

.container {
  min-height: 100%;
  padding-block: 12px;
  padding-inline: 16px;
  padding-bottom: 90px;
  z-index: 0 !important;
  overflow-y: clip;

  .headerResponsive {
    width: 100%;
    // padding: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .topRight {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      > span {
        border-radius: 50px;
        background: #de7515;
        display: flex;
        padding: 6px 16px;
        align-items: center;
        justify-content: center;
        color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

        /* Text S/Medium */
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 12px;
        cursor: pointer;
        > span {
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .bottomRight {
      display: flex;
      // align-items: center;
      gap: 8px;
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 8px;
        .icon {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 10px;
          justify-content: center;
          border-radius: 8px;
          background: var(--Primary-Surface, #f5f7fe);
          height: 30px;
          width: 30px;
        }

        .titleBox {
          display: flex;
          flex-direction: column;
          gap: 4px;
          > span:first-child {
            color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

            /* Caption 2/Reguler */
            // font-family: Poppins;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 145.455% */
          }
          > span:last-child {
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text M/Bold */
            // font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }

  .content {
    z-index: 0;
  }

  .buttonsWrapper {
    padding-top: 16px;
    background: rgba(250, 250, 250, 0.98);
    border-top: 1px solid $NEUTRAL-40;
    position: fixed;
    padding: 16px 30px;
    height: 76px;
    bottom: 0;
    bottom: 0;
    right: 0;
    left: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .innerButtonsWrapper {
      max-width: 1440px;
      margin-inline: auto;
      display: flex;
      gap: 12px;
      justify-content: flex-end;
      flex-grow: 1;
    }

    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      border: none;
      font-family: "Poppins", sans-serif;
      display: flex;
      align-items: center;
      gap: 8px;

      &.previous {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &.next {
        background: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

.contractType {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: var(--Color-Neutal-Neutral-10, #fff);
  padding: 8px;
  margin-bottom: 16px;
  .icon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .desc {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span:first-child {
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      /* Text L/SemiBold */
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
    > span:last-child {
      color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-inline: 18px;
    padding-block: 16px;
    .content {
      padding-bottom: 120px;
    }

    .buttonsWrapper {
      left: 0;
      &.height {
        height: 120px;
      }
      // max-height: 150px;
      .innerButtonsWrapper {
        flex-direction: column;
      }

      button {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .headerResponsive {
      .topRight {
        flex-direction: column;
        align-items: flex-start;
      }
      .bottomRight {
        flex-direction: column;
      }
    }
  }
}
