@import "../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
  .iconQuestionMark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 141px;
    padding-top: 16px;
  }

  .header {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: $NEUTRAL_100;
    }

    .icon {
      color: $NEUTRAL_100;
      cursor: pointer;
    }
  }

  .iconWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding: 0 24px;
    text-align: center;

    .text {
      font-size: 14px;
      font-weight: 400;
      color: $NEUTRAL_90;
      > span {
        font-weight: 600;
      }
    }
  }

  .buttonSection {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 24px;
    > span {
      font-size: 12px;
      color: red;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .primaryButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $PRIMARY_MAIN;
        padding: 10px 0;
        flex: 1;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        cursor: pointer;
        &.disabled {
          background-color: $PRIMARY_HOVER;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_10;
        }
      }

      .primaryButton:hover {
        background-color: $PRIMARY_HOVER;
      }

      .secondaryButton {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $NEUTRAL_10;
        padding: 10px 0;
        border-radius: 8px;
        border: 1px solid $NEUTRAL_60;
        cursor: pointer;
        &.disabled {
          background-color: $NEUTRAL_30;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_90;
        }
      }

      .secondaryButton:hover {
        background-color: $NEUTRAL_30;
      }
    }
  }
}

@media (width <= 768px) {
  .container {
    width: 100%;
  }
}
