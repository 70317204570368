@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  height: calc(100vh - 85px - 32px);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    .searchbar {
      width: 360px;
      height: 45px;
    }
    > span {
      color: var(--Neutral-100, #000);

      /* Text L/SemiBold */
      //   font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
  .tableWrapper {
    height: 100%;
    height: 50vh;
    // min-height: 350px;
    flex-grow: 1;
    // background-color: red;
  }

  .profileBoxRow {
    width: 250px;
    display: flex;
    align-items: center;
    gap: 10px;
    .images {
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        object-fit: cover;
      }
    }

    .profile {
      display: flex;
      flex-direction: column;
      gap: 2px;
      > span:first-child {
        color: var(--Neutral-100, #0a0a0a);
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: uppercase;
      }
      > span:last-child {
        color: var(--Neutral-60, #9e9e9e);

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }

  .clientBoxRow {
    width: 200px;
    overflow: hidden;
    color: var(--Neutral-100, #0a0a0a);
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }
}

@media screen and (max-width: 768px) {
  .container {
    .stickyTop {
      position: sticky;
      top: 82px;
      z-index: 2;
      background-color: $NEUTRAL_10;
    }

    .tableWrapper {
      height: 100%;
      height: 50vh;
      // min-height: 350px;
      flex-grow: 1;
      // background-color: red;
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .header {
      flex-direction: column;
      align-items: flex-start;
      .searchbar {
        width: 100%;
      }
    }
  }
}
