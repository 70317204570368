@import "../../../../../theme/variables.scss";

.container {
  @include padding-inline(30, 24, 24px);
  @include padding-block(24, 16, 16px);
  max-width: 1440px;
  margin-inline: auto;

  .tablesWrapper {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    @include gap(20, 16, 16px);

    &.rbm {
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    }

    &.bm {
      grid-template-columns: 1fr auto;
    }

    .ppWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      width: 200px;
      .pp {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        > img {
          width: 30px;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: 50%;
        }

        > div {
          display: flex;
          flex-direction: column;
          width: 100%;
          > span {
            font-family: "Poppins";

            &:first-child {
              font-weight: 500;
              font-size: 13px;
            }

            &:last-child {
              color: $NEUTRAL-90;
              font-size: 11px;
            }
          }
        }
      }
    }

    .clientWrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .logo {
        background-color: $PRIMARY_SOFT;
        width: 36px;
        aspect-ratio: 1;
        border-radius: 50%;
        display: grid;
        place-items: center;
      }

      .desc {
        > span {
          font-size: 13px;
          font-weight: 500;
        }

        .clientName {
          display: flex;
          align-items: center;
          gap: 4px;

          > img {
            width: 20px;
            aspect-ratio: 1;
            border-radius: 50%;
          }

          > span {
            font-size: 13px;
          }
        }
      }
    }

    .totalClient {
      width: 70px;
      color: #000;

      /* Text M/Bold */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }

    .tooltipWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      // z-index: 0;

      > * {
        width: 100%;
        // z-index: 0;
      }

      .tooltip {
        .qIndicator {
          background-color: $PRIMARY_MAIN;
          padding: 6px 8px;
          border-radius: 4px 4px 0 0;
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: space-between;
          font-size: 12px;
        }

        .tLegends {
          display: grid;
          grid-template-columns: 1fr;
          gap: 4px;
          padding: 6px 8px;

          > p {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 133.333%;
            color: $NEUTRAL-100;
            // display: grid;
            // grid-template-columns: auto 65px auto 1fr;
            display: flex;
            align-items: center;
            gap: 8px;
            text-align: left;

            > span {
              &.bullet {
                width: 5px;
                height: 5px;
                border-radius: 50%;

                &.fulfilled {
                  background-color: $PRIMARY_MAIN;
                }

                &.vendor {
                  background-color: #c1daff;
                }

                &.vacant {
                  background-color: #e0e0e0;
                }
              }

              &:nth-child(2) {
                width: 60px;
              }
            }
          }

          > button {
            background-color: transparent;
            color: $PRIMARY_MAIN;
            text-decoration: underline;
            font-family: "Poppins", sans-serif;
            padding: 0;
            border: none;
            margin-top: 8px;
            font-size: 14px;

            &:hover {
              font-weight: 500;
            }
          }
        }
      }

      .odd {
        td {
          background-color: $PRIMARY_SURFACE !important;
        }
      }
    }

    .section {
      background-color: $NEUTRAL-10;
      padding: 16px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .sHeader {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .shTop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;

          .legends {
            display: flex;
            align-items: center;
            gap: 24px;

            .legend {
              display: flex;
              align-items: center;
              gap: 8px;

              > div {
                width: 37px;
                height: 10px;
                border-radius: 10px;
              }

              > p {
                font-size: 13px;
                font-weight: 500;

                > span {
                  font-style: italic;
                }
              }
            }
          }
        }

        .shBottom {
          display: flex;
          align-items: center;
          gap: 8px;

          > div {
            height: 44px;
            flex-grow: 1;
          }

          > button {
            background-color: transparent;
            border: none;
            color: $PRIMARY_MAIN;
            display: flex;
            align-items: center;
            gap: 8px;

            > span {
              font-size: 14px;
            }

            &:disabled {
              color: $PRIMARY_DISABLED;
            }
          }
        }
      }
    }

    .logLoading {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 10px;
      padding-bottom: 10px;
      .top {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
        > div {
          display: flex;
          align-items: center;
          gap: 4px;
          > div {
            width: 6px;
            height: 6px;
            border-radius: 50px;
            background-color: #1571de;
          }
        }
      }
    }

    .logCard {
      background-color: $NEUTRAL-10;
      width: 504px;
      padding: 16px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      h3 {
        font-size: 20px;
        font-weight: 600;
      }

      .firstLoadLoading {
        height: 300px;
        display: grid;
        place-items: center;
      }

      .empty {
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;

        > img {
          width: 50px;
          aspect-ratio: 100%;
        }

        > span {
          &:nth-child(2) {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            display: block;
            padding-top: 16px;
            padding-bottom: 2px;
          }

          &:last-child {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL_60;
          }
        }
      }

      .logs {
        display: flex;
        flex-direction: column;
        min-height: 300px;
        flex-grow: 1;

        &.centered {
          align-items: center;
          justify-content: center;
        }

        .log {
          display: flex;
          gap: 4px;
          padding: 8px;

          &:not(:last-child) {
            border-bottom: 1px solid $NEUTRAL-30;
          }

          .bullet {
            margin-top: 6px;
            background-color: $PRIMARY_MAIN;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }

          .desc {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .dTop {
              display: flex;
              align-items: center;
              gap: 4px;

              > h4 {
                flex-grow: 1;
                font-size: 12px;
                font-weight: 500;
                color: $PRIMARY_MAIN;
              }

              > span {
                font-size: 11px;
              }
            }

            .dBottom {
              display: flex;
              flex-direction: column;

              .dClientName {
                display: flex;
                align-items: center;
                gap: 3px;

                > span {
                  font-size: 11px;
                  color: $NEUTRAL-60;
                }
              }

              .dLabel {
                > p {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  .seeMoreWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      color: $NEUTRAL-60;
      font-size: 14px;

      &:hover {
        color: $NEUTRAL-100;
      }
    }
  }

  .isLoadingMore {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      display: flex;
      align-items: center;
      color: $NEUTRAL-60;
      font-size: 14px;
    }
  }
}

.totalClientLabel {
  display: flex;
  flex-direction: column;
  border-left: 1px solid black;
}
