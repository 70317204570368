@import '../../../../../theme/variables.scss';

.container {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: $NEUTRAL_10;
    max-width: 100vw;
    width: 436px;
    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
    gap: 16px;

    .header {
        padding: 16px 24px 0px 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .imageWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 54px;
            width: 54px;
            border-radius: 50px;
            background-color: rgba(21, 113, 222, 0.1);
            color: $PRIMARY_MAIN;
        }
    }

    .textWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 24px;
        text-align: left;

        .title {
            font-size: 20px;
            font-weight: 600;
            color: $NEUTRAL_90;
        }

        .text {
            font-size: 14px;
            font-weight: 400;
            color: $NEUTRAL_60;
        }
    }

    .selectBMBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;

        .selectBMWrapper {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $NEUTRAL_40;
            padding: 10px 12px;
            border-radius: 8px;
            gap: 8px;

            .stackedProfile {
                display: flex;
                gap: 8px;
                align-items: center;

                > img {
                    aspect-ratio: 1;
                    object-fit: cover;
                    border-radius: 50%;
                    flex-shrink: 0;
                }

                > span {
                    color: $NEUTRAL_90;
                    font-weight: 400;
                    font-size: 14px;
                }
            }

            .buttonIcon {
                cursor: pointer;
            }

            .popEditBMWrapper {
                position: absolute;
                top: 50px;
                left: 0;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-height: 200px;
                overflow-y: auto;
                background-color: $NEUTRAL_10;
                border-radius: 12px;
                box-shadow: 0px 6px 16px 0px #0000001f;
                padding: 16px 0px;
                gap: 8px;

                .searchWrapper {
                    display: flex;
                    padding: 0px 16px;
                    width: 100%;
                }

                .bmListWrapper {
                    display: flex;
                    flex-direction: column;

                    .bmListBox {
                        padding: 16px;
                        cursor: pointer;

                        &.notLastItem {
                            border-bottom: 1px solid $NEUTRAL_40;
                        }
                    }
                }
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        gap: 8px;

        .primaryButton {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $PRIMARY_MAIN;
            padding: 10px 0;
            border-radius: 8px;
            border: 1px solid $PRIMARY_MAIN;
            cursor: pointer;

            .text {
                font-size: 14px;
                font-weight: 500;
                color: $NEUTRAL_10;
            }
        }

        .primaryButton:hover {
            background-color: $PRIMARY_HOVER;
        }

        .secondaryButton {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $NEUTRAL_10;
            padding: 10px 0;
            border-radius: 8px;
            border: 1px solid $PRIMARY_MAIN;
            cursor: pointer;

            .text {
                font-size: 14px;
                font-weight: 500;
                color: $PRIMARY_MAIN;
            }
        }

        .secondaryButton:hover {
            background-color: $NEUTRAL_30;
        }
    }
}

@media (width <= 768px) {
    .container {
        width: 100%;
    }
}
