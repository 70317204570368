.customScrollbar {
  overflow-y: auto;
  height: 100%; /* Atur tinggi sesuai kebutuhan */
  // border-radius: 10px;
  &.noScroll::-webkit-scrollbar {
    display: none;
  }
}

.customScrollbar::-webkit-scrollbar {
  width: 8px; /* Lebar scrollbar */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 10px;
  /* Membuat thumb tampak lebih pendek */
}
.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a39e9e; /* Warna thumb saat di-hover */
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Warna jalur scrollbar */
}
