@import "../../../../../../theme/variables.scss";

.container {
  // display: grid;
  // grid-template-columns: 256px auto;
  display: flex;
  gap: 8px;
  max-width: 1440px;
  margin-inline: auto;
  overflow: clip;

  .index {
    border-radius: 8px;
    overflow: clip;
    // height: 482px;
    // display: flex;
    // flex-direction: column;
    // overflow: auto;
    width: 256px;
    background-color: $NEUTRAL-10;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 52px 1fr;

    .iHeader {
      background-color: $PRIMARY_MAIN;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 8px;
      height: 52px;
      z-index: 1;
      // position: sticky;
      // top: 0;

      .iHLogo {
        background-color: $PRIMARY_SOFT;
        width: 32px;
        aspect-ratio: 1;
        flex-shrink: 0;
        border-radius: 50%;
        display: grid;
        place-items: center;
      }

      > span {
        font-size: 14px;
        font-weight: 700;
        color: $NEUTRAL-10;
      }
    }

    .iContent,
    .iEmpty {
      background-color: $NEUTRAL-10;
      flex-grow: 1;

      &.iEmpty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        > img {
          width: 183px;
          object-fit: contain;
        }

        > span {
          text-align: center;
          font-size: 14px;
          color: $NEUTRAL-60;
        }
      }

      &.iContent {
        display: flex;
        flex-direction: column;
        padding: 12px 8px;
        padding-top: 0;
        overflow: auto;
        // gap: 12px;

        .icHeader {
          display: flex;
          flex-direction: column;
          gap: 12px;
          position: sticky;
          top: 0;
          // top: 52px;
          background-color: $NEUTRAL-10;
          padding-block: 12px;
          z-index: 1;

          > button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            background-color: $NEUTRAL-10;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid $PRIMARY_MAIN;
            color: $PRIMARY_MAIN;
          }

          .searchWrapper {
            height: 44px;
          }
        }

        .iList {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .each {
            .eHeader {
              padding: 8px;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              cursor: pointer;
              transition: background-color ease 0.3s;

              .ehTop {
                display: flex;
                align-items: center;
                gap: 4px;

                > span {
                  flex-grow: 1;
                  font-size: 14px;
                  font-weight: 500;
                }

                .cityAmount {
                  padding: 3px 6px;
                  border-radius: 6px;
                  font-size: 11px;
                  background-color: $PRIMARY_DISABLED;
                }

                > button {
                  background-color: transparent;
                  border: none;
                  display: grid;
                  place-items: center;
                }
              }

              .ehBottom {
                display: flex;
                align-items: center;
                gap: 2px;
                color: $NEUTRAL-70;

                > span {
                  font-size: 11px;
                  flex-grow: 1;
                }

                > button {
                  background-color: transparent;
                  border: none;
                  display: grid;
                  place-items: center;
                  color: $PRIMARY_DISABLED;
                }
              }

              &:hover {
                background-color: $PRIMARY_SURFACE;
                color: $PRIMARY_MAIN;
              }

              &.error {
                border: 1px solid red;
              }

              &.active {
                background-color: $PRIMARY_MAIN;
                color: $NEUTRAL-10;

                .ehTop {
                  .cityAmount {
                    background-color: $NEUTRAL-10;
                    color: $NEUTRAL-100;
                  }

                  > button {
                    color: $NEUTRAL-10;
                  }
                }

                .ehBottom {
                  color: $NEUTRAL-10;
                }
              }
            }

            .eContent {
              padding-top: 8px;
              padding-left: 24px;

              > div {
                &:first-child {
                  border-left: 1px solid $NEUTRAL-50;
                  padding-left: 8px;
                }

                &.active {
                  border-color: $PRIMARY_MAIN;
                }

                &.eSearchWrapper {
                  padding-bottom: 8px;
                }

                &.ecList {
                  .ecCity {
                    border-left: 1px solid $NEUTRAL-50;
                    padding-left: 8px;

                    > div {
                      display: flex;
                      align-items: center;
                      gap: 4px;
                      border-radius: 8px;
                      cursor: pointer;
                      padding: 8px;

                      &[is-completed="true"] {
                        color: $PRIMARY_MAIN !important;
                      }
                      &[is-completed="false"] {
                        color: $NEUTRAL-60;

                        > span {
                          color: $NEUTRAL-100;
                        }
                      }
                      &[is-applied="false"] {
                        > * {
                          color: $NEUTRAL-50 !important;
                        }
                      }

                      > span {
                        flex-grow: 1;
                        font-size: 14px;
                      }
                    }

                    &.active {
                      border-color: $PRIMARY_MAIN;

                      > div {
                        background-color: $PRIMARY_SOFT;

                        > span {
                          color: $PRIMARY_MAIN;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .main {
    // background-color: $NEUTRAL-10;
    // height: 482px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    .noPosition {
      background-color: $NEUTRAL-10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      @include padding(24, 16, 16px);
      flex-grow: 1;
      text-align: center;

      > img {
        width: 200px;
        object-fit: contain;
        max-width: 200px;
      }

      .desc {
        padding-top: 12px;
        @include padding-bottom(24, 16, 16px);
        display: flex;
        flex-direction: column;
        gap: 5px;
        max-width: 500px;

        > h3 {
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 27px */
          letter-spacing: 0.09px;
          color: $PRIMARY_MAIN;
          max-width: 350px;
        }

        > p {
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 153.846% */
          color: $NEUTRAL-70;
        }
      }

      > button {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: $NEUTRAL-10;
        padding: 12px;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        color: $PRIMARY_MAIN;

        > span {
          font-size: 14px;
        }
      }
    }
  }
}

.positionData {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.hidden {
    display: none;
  }

  .qCard {
    background-color: $NEUTRAL-10;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .qHeader {
      display: flex;
      align-items: center;
      gap: 8px;

      .qhLeft {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > span {
          font-size: 14px;
          font-weight: 700;
          color: $PRIMARY_MAIN;
        }

        > div {
          display: flex;
          align-items: center;
          gap: 8px;

          > p {
            font-size: 14px;

            > span {
              font-weight: 700;
            }
          }
        }
      }

      > button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }
    }

    .qContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      > div {
        width: 100%;
      }

      > button {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        border: none;
        color: $PRIMARY_MAIN;
        font-size: 14px;
      }
    }
  }
}

.cityData {
  background-color: $NEUTRAL-10;
  border-radius: 16px;

  &.hidden {
    display: none;
  }

  .cHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-bottom: 1px solid $NEUTRAL-30;

    > span {
      flex-grow: 1;
      font-size: 20px;
      font-weight: 700;
      color: $PRIMARY_MAIN;
    }

    > button {
      background-color: $NEUTRAL-10;
      border: 1px solid $PRIMARY_MAIN;
      color: $PRIMARY_MAIN;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border-radius: 8px;
    }
  }

  .cContent {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .amount {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      padding: 16px;
      background-color: $NEUTRAL-20;
      border-radius: 16px;

      > span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 153.846%;
        color: $PRIMARY_MAIN;
      }
    }

    .form {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 16px;
      border-radius: 16px;
      border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
      background: var(--Color-Neutal-Neutral-20, #f5f5f5);
      padding: 16px;

      .salarySection {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        gap: 16px;

        .salary {
          display: grid;
          grid-template-columns: 1fr;
          // gap: 8px;

          .salaryFields {
            display: grid;
            grid-auto-flow: column;
            gap: 4px;
          }
        }

        .equipments,
        .allowances {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .multifieldsWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .multifields {
              display: flex;
              align-items: flex-end;
              gap: 8px;

              .inputForm {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 4px;

                .dropdown {
                  width: 100%;
                }

                .iconDelete {
                  width: 20px;
                  cursor: pointer;
                  display: grid;
                  place-items: center;
                  background-color: transparent;
                  border: none;
                  color: $DANGER_MAIN;
                  margin-left: 4px;

                  &:disabled {
                    color: $NEUTRAL_60;
                  }
                }
              }
            }
          }
        }
      }

      .fEach {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 12px;

        border-bottom: 1px solid #ededed;
        padding-bottom: 16px;
        &.noBorder {
          border: none;
          padding-bottom: 0px;
        }

        .fEHeader {
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: space-between;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 142.857%;
            color: $PRIMARY_MAIN;
          }
        }

        .valueBPU {
          background-color: $PRIMARY_SOFT;
          padding: 4px 8px;
          border-radius: 32px;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 133.333%;
          color: $PRIMARY_MAIN;
        }

        .bpuBox {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .bpuWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .titleBox {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 16px;
              .left {
                width: 100%;
                color: #000;

                /* Text M/Bold */
                // font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 142.857% */
              }

              .right {
                display: flex;
                align-items: center;
                gap: 8px;
                width: 80%;
                .icon {
                  width: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .desc {
                  color: var(
                    --Neutral-70,
                    var(--Color-Neutal-Neutral-70, #757575)
                  );

                  /* Caption 1/Reguler */
                  // font-family: Poppins;
                  font-size: 12px;
                  font-style: italic;
                  font-weight: 400;
                  line-height: 16px; /* 133.333% */
                }

                .percentage {
                  display: flex;
                  align-items: center;
                  border-radius: 8px;
                  background: var(--Color-Neutal-Neutral-40, #e0e0e0);
                  padding: 8px 10px;
                  position: relative;
                  width: 160px;
                  > span:first-child {
                    color: var(
                      --Neutral-100,
                      var(--Color-Neutal-Neutral-19, #0a0a0a)
                    );

                    /* Text M/Reguler */
                    // font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                  }
                  > span:last-child {
                    position: absolute;
                    right: 0;
                    border-radius: 0px 7px 7px 0px;
                    background: var(--Color-Neutal-Neutral-30, #ededed);
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(
                      --Neutral-60,
                      var(--Color-Neutal-Neutral-60, #9e9e9e)
                    );
                    text-align: center;

                    /* Text S/Reguler */
                    // font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 153.846% */
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 16px;
}

.buttonResponsive {
  position: fixed;
  left: 0;
  background-color: #000;
  z-index: 3;
  border-radius: 0px 8px 8px 0px;
  background: var(--Primary-Main, #1571de);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 7px 0px 6px;
  top: 200px;
  width: 30px;
  cursor: pointer;
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
    0px 3px 5px rgba(9, 30, 66, 0.2);
}

@media screen and (max-width: 768px) {
  .container {
    .main {
      .noPosition {
        > img {
          width: 100px;
          max-width: 100px;
        }
      }
    }
  }
  .cityData {
    .cHeader {
      flex-direction: column;
      align-items: flex-start;
      > button {
        width: 100%;
      }
    }
    .cContent {
      .form {
        .fEach {
          .bpuBox {
            .bpuWrapper {
              .titleBox {
                flex-direction: column;
                gap: 8px;
                .right {
                  width: 100%;
                }
              }
            }
          }
        }

        .salarySection {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
