@import "../../../../../../theme/variables.scss";

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll !important;
  z-index: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  max-height: 500px;

  &.grow {
    // height: 100px;
    height: 360px;
    flex-grow: 1;
  }

  .holdText {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
  }

  .resultPopup {
    position: absolute;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    right: 16px;
    top: 60px;
    width: 184px;
    z-index: 1;
    // padding: 4px 12px;

    > * {
      text-align: left;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_90;
      position: relative;

      &:not(.divider) {
        padding: 8px 12px;
      }

      .divider {
        width: calc(100% - 24px);
        height: 1px;
        background-color: $NEUTRAL_40;
        position: absolute;
        bottom: 0;
      }

      &:hover {
        background-color: $NEUTRAL_20;
      }

      // &:first-child {
      //   // border-bottom: 1px solid $NEUTRAL_40;
      // }
    }
  }

  .showOnMobile {
    display: none;
  }

  .emptyWrapper {
    flex-grow: 1;
    height: 360px;
  }

  .tableContainer {
    overflow: auto;
    background-color: inherit;
    border-bottom: 0;
    // border: 1px solid $NEUTRAL_50;
    border-top: 0;
    border-inline: none;
    overflow-y: scroll !important;

    // height: auto;
    min-height: 360px;

    &.grow {
      height: auto;
      min-height: unset;
      flex-grow: 1;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;
      //   min-width: 900px !important;
      width: 100%;

      .tableHead {
        .tableHeadRow {
          .tableHeadCell {
            padding-inline: 20px !important;
            padding-block: 16px !important;
            position: sticky;
            top: 0;
            background-color: $NEUTRAL-10;
            z-index: 2;

            &:first-child {
              border-right: 1px solid $NEUTRAL_40;
              position: sticky;
              left: 0;
              background-color: $NEUTRAL_10 !important;
              z-index: 3;
              padding-inline: 16px !important;

              > div {
                width: 304px;
                display: flex;
                align-items: center;
                gap: 16px;
              }
            }

            &.status {
              position: sticky;
              right: 0;
              z-index: 2;
              background-color: $NEUTRAL_10 !important;
              border-left: 1px solid $NEUTRAL_40;
              width: 4px !important;
              // z-index: 2;

              .statusOption {
                position: absolute;
                // background-color: red;
                width: 100%;
                height: 200px;
              }

              div {
                width: 4px !important;
                display: flex;
                justify-content: center;

                button {
                  margin: auto;
                  background-color: transparent;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;

                  &.Expand {
                    color: $PRIMARY_MAIN;

                    span {
                      color: $PRIMARY_MAIN !important;
                    }
                  }
                }
              }
            }

            div {
              width: 220px !important;
            }

            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              line-height: 20px;
              color: $NEUTRAL_100;
            }
          }
        }
      }

      .tableBody {
        .tableCell {
          padding: 16px !important;

          .statusBox {
            display: flex;
            align-items: center;
            gap: 4px;

            .remarkBox {
              color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

              /* Text S/Reguler */
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              line-height: 20px; /* 153.846% */
              > span {
                font-weight: 400;
                > span {
                  font-weight: 700;
                }
              }
            }
            .statusSpan {
              display: flex;
              // height: 28px;

              padding: 4px 9px;
              justify-content: center;
              align-items: center;
              border-radius: 31px;
              background: #f7ead0;
              color: #d58d00;
              text-align: center;

              /* Text M/Reguler */
              // font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              &.noStatus {
                background: none;
                color: #000;
              }
              &.red {
                background: var(--Danger-Surface, #faeff2);
                color: var(--Danger-Main, #ff3e13);
              }
              &.green {
                background: var(--Success-Surface, #eafef3);
                color: var(--Success-Main, #27bb83);
              }
              &.grey {
                background: var(--Color-Neutal-Neutral-30, #ededed);
                color: #b2a8a8;
              }

              &.pink {
                background: #ffeafd;
                color: #f400a2;
              }
            }
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              // position: relative;

              // .remark {
              //   position: absolute;
              //   top: 20px;
              //   width: 300px;
              //   background-color: #363535;
              //   border-radius: 4px;
              //   padding: 10px;
              //   z-index: 2;
              //   color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

              //   /* Text S/Reguler */
              //   font-family: Poppins;
              //   font-size: 12px;
              //   font-style: normal;
              //   line-height: 20px; /* 153.846% */
              //   > span {
              //     font-weight: 400;
              //     > span {
              //       font-weight: 700;
              //     }
              //   }
              // }
            }
          }

          .emailBox {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > div {
              display: flex;
              align-items: center;
              gap: 4px;
              > span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                line-height: 20px;
                color: $NEUTRAL_100;
                overflow: hidden;
                display: -webkit-box;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .iconCopy {
                cursor: pointer;
              }
              .iconCopy:hover {
                color: #1571de;
              }
            }
          }

          .actionBox {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 4px !important;
            .selectedDropdown {
              position: absolute;
              top: 40px;
              // bottom: 0px;
              right: -14px;
              display: flex;
              flex-direction: column;

              align-items: flex-start;
              min-width: 200px;
              gap: 8px;
              border-radius: 8px;
              background: var(--Color-Neutal-Neutral-10, #fff);
              padding-top: 12px;
              padding-bottom: 12px;

              /* Shadow/700 */
              box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

              z-index: 1;
              &.Position {
                top: -76px;
              }

              &.PositionPenilaian {
                top: -160px;
              }

              &.PositionInterviewClient {
                top: -105px;
              }
              > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                cursor: pointer;
                padding: 0px 12px 0px 12px;
                width: 100%;
                position: relative;
                &.stepBackBox {
                  cursor: unset;
                  .back {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;
                    position: relative;
                    > span {
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      color: var(--Primary-Main, #1571de);

                      /* Text M/Medium */
                      // font-family: Poppins;
                      font-size: calc(
                        12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                      );
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px; /* 142.857% */
                      text-align: left;
                    }
                  }
                }

                > span {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  color: var(--Primary-Main, #1571de);

                  /* Text M/Medium */
                  // font-family: Poppins;
                  font-size: calc(
                    12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                  );
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
                  text-align: left;
                }

                .dropdwonChild {
                  position: absolute;
                  top: 0px;
                  right: 202px;

                  display: flex;
                  flex-direction: column;

                  align-items: flex-start;
                  min-width: 200px;
                  gap: 4px;
                  border-radius: 8px;
                  background: var(--Color-Neutal-Neutral-10, #fff);
                  padding-top: 8px;
                  padding-bottom: 8px;

                  /* Shadow/700 */
                  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

                  // z-index: 2;
                  &.Position {
                    top: -86px;
                  }

                  &.PositionPeralihanStepBack {
                    top: -36px;
                  }

                  &.PositionRecommanded {
                    top: -130px;
                  }

                  &.PositionOffering {
                    top: -180px;
                  }

                  &.PositionOfferingOther {
                  }

                  > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;
                    cursor: pointer;
                    padding: 0px 12px 0px 12px;
                    width: 100%;
                    position: relative;
                    > span {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      color: var(--Primary-Main, #1571de);

                      /* Text M/Medium */
                      // font-family: Poppins;
                      font-size: calc(
                        12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                      );
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px; /* 142.857% */
                      text-align: left;
                    }

                    &.selected {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 8px;
                      cursor: unset;
                      > span:first-child {
                        color: var(
                          --Neutral-90,
                          var(--Color-Neutal-Neutral-90, #404040)
                        );

                        /* Caption 1/Reguler */
                        // font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px; /* 133.333% */
                      }

                      > span:last-child {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: var(--Primary-Main, #1571de);

                        /* Text M/Medium */
                        // font-family: Poppins;
                        font-size: calc(
                          12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                        );
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 142.857% */
                        text-align: left;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }

            > div:first-child {
              padding: 8px 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
              background: var(--Color-Neutal-Neutral-10, #fff);
              cursor: pointer;
              position: relative;
              left: 3px;

              &.disabled {
                background: #f5f5f5;
                border: none;
              }
            }
          }

          > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 20px;
            color: $NEUTRAL_100;
            overflow: hidden;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .statusText {
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

            /* Text M/Regular */
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }

          &.stickyLeft {
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            border-right: 1px solid $NEUTRAL_40;

            > div {
              display: flex;
              align-items: center;
              gap: 16px;

              .stackedProfile {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                span {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: calc(
                    10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
                  );
                  line-height: 20px;
                  color: $PRIMARY_MAIN;
                  text-align: left;
                }

                .positionBox {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  > span {
                    color: var(--Neutral-80, #616161);

                    /* Caption 1/Reguler */
                    // font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */
                  }
                  > div {
                    background-color: #616161;
                    width: 5px;
                    height: 5px;
                    border-radius: 50px;
                  }
                }
              }
            }
          }

          &.stickyRight {
            position: sticky;
            right: 0;
            background-color: $NEUTRAL_10;
            border-left: 1px solid $NEUTRAL_40;
            // z-index: 9999;
          }

          // &.status {
          //   // padding: 16px !important;

          //   // button {
          //   //   width: 100%;
          //   //   height: 100%;
          //   //   padding-block: 8px;
          //   //   border-radius: 8px;
          //   //   border: none;

          //   //   font-family: Poppins !important;
          //   //   font-style: normal;
          //   //   font-weight: 400;
          //   //   font-size: 14px;
          //   //   line-height: 20px;
          //   //   text-align: center;
          //   //   color: #ffffff;

          //   //   &.invite {
          //   //     background-color: $PRIMARY_MAIN;
          //   //   }

          //   //   &.result {
          //   //     background-color: $NEUTRAL_10;
          //   //     border: 1px solid $PRIMARY_MAIN;
          //   //     color: $PRIMARY_MAIN;
          //   //     display: flex;
          //   //     align-items: center;
          //   //     justify-content: center;
          //   //     gap: 4px;
          //   //     position: relative;
          //   //   }

          //   //   &.invited {
          //   //     background-color: $PRIMARY_DISABLED;
          //   //     cursor: auto;
          //   //   }

          //   //   &.accepted {
          //   //     background-color: $SECONDARY_SOFT;
          //   //     color: $SECONDARY_MAIN;
          //   //     border: 1px solid $SECONDARY_MAIN;
          //   //   }

          //   //   &.rejected {
          //   //     background-color: transparent;
          //   //     color: $DANGER_MAIN;
          //   //     border: none;
          //   //     cursor: auto;
          //   //   }
          //   // }
          // }
        }
      }
    }
  }

  .paginationWrapper {
    border-top: 1px solid $NEUTRAL_50;
    position: sticky;
    bottom: 0;
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    width: 100%;
    left: 0;

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  text-decoration: none;
  > span {
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
  .positionName {
    color: var(--Neutral-80, #616161);
    text-align: start;

    /* Caption 1/Reguler */
    // font-family: Poppins;
    font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.remarkBox {
  width: 500px;

  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.linkText {
  overflow: hidden;
  color: var(--Primary-Main, #1571de);
  text-overflow: ellipsis;

  /* Text M/Reguler */
  //   font-family: Poppins;
  font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  text-decoration: underline;
  &.disabledLink {
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
    text-decoration: none;
  }
  &.disabled {
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
  }
}

.buttonDetail {
  display: none;
}

@media screen and (max-width: 768px) {
  .showMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .buttonDetail {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      cursor: pointer;
      // text-decoration: underline;
      color: var(--Color-Neutal-Neutral-10, #fff);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      border-radius: 6px;
      background: var(--Primary-Main, #1571de);

      //   width: 20px;
      //   height: 20px;
      img {
        width: 25px;
        height: 100%;
      }
    }
  }
  .tableWrapper {
    .showOnMobile {
      display: table-cell;
    }

    .tableContainer {
      .table {
        width: 100% !important;

        .tableHead {
          .tableHeadCell {
            padding: 12px 16px !important;

            // &:first-child {
            //   border-right: none !important;
            //   position: relative !important;
            //   left: 0;
            //   background-color: $NEUTRAL_10 !important;
            //   // z-index: 1;
            //   padding-inline: 16px !important;

            //   > div {
            //     width: 100% !important;
            //     display: flex;
            //     align-items: center;
            //     gap: 16px;
            //   }
            // }

            // &.status {
            //   // border-right: none !important;

            //   position: relative !important;
            //   left: 0;
            // }
          }

          .showOnMobile {
            min-width: 100px !important;
            max-width: 150px !important;
          }
        }

        .tableBody {
          .tableCell {
            &.stickyLeft {
              position: relative;
              border-right: none;
              // display: none!important;

              > div {
                width: 100% !important;
              }
            }

            &.showOnMobile {
              button {
                width: 100%;
                padding: 6px 12px;
                background-color: $PRIMARY_MAIN;
                border: none;
                border-radius: 8px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_10;
              }
            }
          }
        }

        .hideOnMobile {
          display: none !important;
        }
      }
    }
  }
}
