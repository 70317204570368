.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > span {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Heading 7/Bold */
    // font-family: Poppins;
    font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }

  .dCity {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
