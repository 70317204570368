.container {
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  display: flex;
  width: 443px;
  padding: 16px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 143.333px;
    background: #fa0;
    width: 40px;
    height: 40px;
  }

  > span:nth-child(2) {
    color: #0f161f;
    text-align: center;

    /* Heading 7/SemiBold */
    // font-family: Poppins;
    font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }

  > span:nth-child(3) {
    color: #878787;
    text-align: center;

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }

  > span:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    background: var(--Color-Neutal-Neutral-10, #fff);
    padding: 12px;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    width: 100%;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 8px 8px 0px 0px;
  }
}
