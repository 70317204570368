@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    // > button {
    //   background-color: transparent;
    //   padding: 0;
    //   display: grid;
    //   place-items: center;
    //   border: none;
    // }

    .dots {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
      background: var(--Color-Neutal-Neutral-10, #fff);
      width: 35px;
      height: 35px;
      cursor: pointer;
      padding: 10px;
    }

    .dropdownOptions {
      position: absolute;
      top: 40px;
      right: 0;
      border-radius: 8px;
      background: var(--Color-Neutal-Neutral-10, #fff);
      width: 200px;
      box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
        0px 3px 5px rgba(9, 30, 66, 0.2);
      z-index: 2;
      display: flex;
      flex-direction: column;
      // gap: 16p;
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 8px 12px;
        cursor: pointer;
        > span {
          color: var(--Primary-Main, #1571de);

          /* Heading 7/SemiBold */
          // font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 30px */
          letter-spacing: 0.1px;
          &.red {
            color: #ff3e13;
          }
        }
        > button {
          background-color: transparent;
          padding: 0;
          display: grid;
          place-items: center;
          border: none;
        }
      }

      > div:hover {
        background: #f5f7fe;
      }
    }
  }

  .header {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $PRIMARY_MAIN;
    }

    > div {
      width: 191px;
      height: 100%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
      }
    }
  }

  .content {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    // gap: 16px;
    // padding-top: 16px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid $NEUTRAL-40;
    overflow: auto;

    > *:not(table) {
      flex-grow: 1;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;

      thead {
        tr {
          th {
            padding: 16px 20px;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            border-bottom: 1px solid $NEUTRAL-40;
          }
        }
      }

      tbody {
        tr {
          &.odd {
            td {
              background-color: $PRIMARY_SURFACE !important;
            }
          }

          td {
            padding: 24px 20px;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL-90;
          }
        }
      }
    }
  }

  .bottomButton {
    display: none;
  }
}

.flbNumbersRow {
  display: flex;
  align-items: center;
  gap: 4px;

  .number {
    color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }

  .more {
    color: var(--Primary-Main, #1571de);

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    cursor: pointer;
  }
}

.lastLoginRow {
  color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nameRow {
  color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

  /* Text S/Reguler */
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  width: 100px;
}
@media screen and (max-width: 768px) {
  .container {
    .header {
      > div {
        display: none;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      padding-top: 8px;
      overflow: auto;
    }

    .bottomButton {
      display: block;
      width: 100%;
      height: 40px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
      }
    }
  }
}
