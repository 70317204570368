@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  background-color: $NEUTRAL_10;
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;

  &.v2 {
    .arrowWrapper {
      padding: 0;

      .arrow {
        border-radius: 4px;
        background-color: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_50;

        &.disabled {
          background-color: $NEUTRAL_30;
          color: $NEUTRAL_50;
        }
      }
    }

    .pageIndicator {
      gap: 8px;
    }

    .page {
      border-radius: 0px;
      background-color: $NEUTRAL_10;
      border: 1px solid $NEUTRAL_40;
      border-radius: 4px;
      padding: 11.5px;
      transition: all 0.3s;

      &.selectedPage {
        background-color: $PRIMARY_MAIN;
      }

      &.disabled {
        background-color: transparent;
        border: none;
      }
    }
  }
}

.leftSide {
  display: flex;
  // flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.rightSide {
  display: flex;
  // flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
  .contentWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-right: 4px;
  }
}

.content {
  padding-right: 16px;
  color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

  /* Caption 1/Reguler */
  // font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.contentBold {
  font-size: 12;
  font-weight: 600;
  color: $NEUTRAL_100;
}
.textContent {
  color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

  /* Caption 1/Reguler */
  // font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.selectWrapper {
  width: 58px;
  height: 24px;
  position: relative;
  top: 2px;
}

.pageIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $NEUTRAL_30;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowWrapper:first-child {
  padding-right: 10px;
}

.arrowWrapper:last-child {
  padding-left: 10px;
}

.page {
  padding: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: $NEUTRAL_100;
}

.selectedPage {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $PRIMARY_MAIN;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: $NEUTRAL_10;
}

.disabled {
  color: $NEUTRAL_70 !important;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 8px;

    .leftSide {
      width: 100%;
      justify-content: space-between;
    }

    .rightSide {
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
