@import "../../../../../../../../theme/variables.scss";

.container {
  z-index: inherit !important;
  padding-bottom: 116px;
  max-width: 1440px;
  margin-inline: auto;

  .buttonsWrapper {
    padding-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 110px;

    .left {
      flex-grow: 1;

      button {
        height: 36px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        padding-inline: 16px;

        &.prev {
          background-color: $NEUTRAL_10;
          border: 1px solid $NEUTRAL_100;
          color: $NEUTRAL_100;
        }
      }
    }

    .right {
      display: flex;
      gap: 16px;
      // width: 436px;
      // max-width: 100vw;
      align-items: center;
      justify-content: flex-end;

      button {
        height: 36px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        padding-inline: 16px;

        &.saveToDraft {
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &.submit {
          background-color: $PRIMARY_MAIN;
          border: 1px solid $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: $PRIMARY_MAIN;
  }

  .content {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .noData {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #333333;
    }

    .each {
      background-color: $NEUTRAL_10;
      padding: 24px;
      border-radius: 8px;
      z-index: 0 !important;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_100;
        padding-bottom: 9px;
      }

      .tableWrapper {
        width: 100%;
        height: 100%;
        z-index: 0 !important;

        .tableContainer {
          border-bottom: 0;
          z-index: 0 !important;
          overflow-x: scroll !important;
          position: relative;

          .table {
            border-collapse: separate;
            background-color: $NEUTRAL_10 !important;

            .showOnMobile {
              display: none;
            }

            .tableHeadCell {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_DARKEST !important;
              border-bottom: 1.5px solid $PLATINUM !important;
              padding-block: 15px !important;
              white-space: nowrap;

              .infoIcon {
                color: $PRIMARY_MAIN;
              }
            }

            .stickyLeft {
              position: sticky;
              left: 0;
              background-color: $NEUTRAL_10 !important;
              z-index: 1;
            }

            .stickyRight {
              position: sticky;
              right: 0;
              background-color: $NEUTRAL_10 !important;
            }

            .tableRow {
              border: 0 !important;

              .tableCell {
                background-color: $NEUTRAL_10;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
                border-bottom: 1.5px solid $PLATINUM !important;
                padding-block: 15px;

                &.showOnMobile {
                  display: none;

                  button {
                    width: 77px;
                    height: 28px;
                    background: #1571de;
                    border-radius: 8px;
                    border: none;
                    color: $NEUTRAL_LIGHTEST;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                  }
                }

                .salary {
                  display: flex;
                  flex-direction: column;

                  > span {
                    &:last-child {
                      font-family: Poppins;
                      font-size: 11px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 16px;
                      color: $NEUTRAL_60;
                    }
                  }
                }
              }

              &.lastRow {
                .tableCell {
                  border-bottom: none !important;
                }
              }
            }
          }
        }
      }

      .summary {
        margin-top: 5px;
        width: 290px;
        float: right !important;
        border-top: 1px solid $NEUTRAL_DARKEST;
        padding-top: 17.5px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .boxSum {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.last {
            border-top: 1px solid $NEUTRAL_DARKEST;
            padding-top: 8px;
            margin-top: 7.5px;
          }

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $NEUTRAL_DARKEST;
          }

          &.last,
          &.bold {
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding-top: 16px;
    padding-bottom: 110px;

    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &:first-child {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &:last-child {
        background: $PRIMARY_MAIN;
        border: 1px solid $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 76px;
    right: 0;
    left: 76px;
    background-color: $NEUTRAL_10;
    margin-left: inherit;
    padding: 12px 24px;
    border-top: 1px solid $NEUTRAL_40;

    display: flex;
    flex-direction: column;

    .innerFooter {
      width: 100%;
      max-width: 1440px;
      margin-inline: auto;
    }

    .top {
      display: flex;
      align-items: center;
      gap: 33px;
      border-bottom: 1px solid $NEUTRAL_40;
      padding-bottom: 12px;

      > * {
        display: flex;
        align-items: center;

        .descWrapper {
          display: flex;
          align-items: center;

          span {
            font-family: "Poppins";
            font-style: normal;

            &:first-child {
              background-color: $PRIMARY_MAIN;
              color: $NEUTRAL_10;
              display: grid;
              place-items: center;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              font-weight: 500;
              font-size: 14px;
            }

            &:nth-child(2) {
              padding-left: 8px;
              padding-right: 24px;
              font-weight: 400;
              font-size: 13px;
              color: $NEUTRAL_60;
            }
          }
        }

        .inputWrapper {
          display: flex;
          align-items: center;
          height: 36px;
          &.redBorder {
            border: 1px solid red;
            border-radius: 8px;
          }

          > * {
            height: 100%;
          }

          input {
            border-radius: 8px 0 0 8px;
            border: 1px solid $NEUTRAL_40;
            padding: 12px;
            transition: 0.2s;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_70;

            &.tOPInput {
              width: 80px;
            }

            &:focus {
              outline: none !important;
              border-color: $PRIMARY_MAIN;
              border-width: 1px;
              border-style: solid;
            }
          }

          .adornment {
            background-color: $NEUTRAL_20;
            display: grid;
            place-items: center;
            padding-inline: 12px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: $NEUTRAL_60;
            border-radius: 0 8px 8px 0;
            border: 1px solid $NEUTRAL_40;
            border-left: 0;
          }
        }

        .inputSelectWrapper {
          display: flex;
          align-items: center;
          height: 36px;
          border: 1px solid $NEUTRAL_40;
          border-radius: 8px;
          &.redBorder {
            border: 1px solid red;
            border-radius: 8px;
          }

          > * {
            height: 100%;
            border: none;
          }

          select {
            border-radius: 8px 0 0 8px;
            border-right: 8px solid transparent;
            background-color: $NEUTRAL_20;
            padding-left: 8px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            text-align: start;
            color: $NEUTRAL_60;

            &:focus {
              outline: none;
            }
          }

          div {
            position: relative;

            input {
              border-radius: 0 8px 8px 0;
              border: none;
              border-left: 1px solid $NEUTRAL_40;
              padding: 12px;
              height: 100%;
              width: 161px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_70;

              &:focus {
                outline: none !important;
                border-color: $PRIMARY_MAIN;
                border-width: 1px;
                border-style: solid;
              }
            }

            input[type="number"]::-webkit-inner-spin-button {
              position: relative;
              left: -15px; /* Menggeser panah ke kiri */
            }

            .rupiahAdornment {
              position: absolute;
              left: 8px;
              top: 6px;
            }

            .percentageAdornment {
              position: absolute;
              right: 12px;
              top: 6px;
              width: 10px;

              color: $NEUTRAL_70;

              &.on {
                opacity: 1;
              }

              &.off {
                opacity: 0 !important;
              }
            }
          }
        }

        .inputSelectModal {
          display: none;
          align-items: center;
          height: 36px;
          border: 1px solid $NEUTRAL_40;
          border-radius: 8px;

          > * {
            height: 100%;
            border: none;

            &:first-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-radius: 8px 0 0 8px;
              border-right: 8px solid transparent;
              background-color: $NEUTRAL_20;
              padding-left: 8px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              text-align: start;
              color: $NEUTRAL_60;
            }

            &:nth-child(2) {
              position: relative;

              input {
                border-radius: 0 8px 8px 0;
                border: none;
                border-left: 1px solid $NEUTRAL_40;
                padding: 12px;
                height: 100%;
                width: 161px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_70;

                &:focus {
                  outline: none !important;
                  border-color: $PRIMARY_MAIN;
                  border-width: 1px;
                  border-style: solid;
                }
              }

              .rupiahAdornment {
                position: absolute;
                left: 8px;
                top: 6px;
              }

              .percentageAdornment {
                position: absolute;
                right: 8px;
                top: 6px;
                width: 10px;
                color: $NEUTRAL_70;

                &.on {
                  opacity: 1;
                }

                &.off {
                  opacity: 0 !important;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;

      > * {
        &:first-child {
          display: flex;
          align-items: center;
          gap: 16px;

          .moneyIcon {
            color: $NEUTRAL_60;
          }

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $NEUTRAL_100;
          }
        }

        &:last-child {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 140%;
          text-align: right;
          color: $NEUTRAL_100;
        }
      }
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    width: 100%;

    &.onHide {
      bottom: -500px;
    }

    &.onShow {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.Visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    left: 0 !important;
  }

  .container {
    .footer {
      bottom: 110px;
    }

    .content {
      padding-bottom: 50px;
    }
  }

  .buttonsWrapper {
    width: 100%;
    flex-direction: column-reverse;
    padding-bottom: 200px !important;

    .left,
    .right,
    button {
      width: 100%;
    }

    .right {
      gap: 12px;
    }
  }

  .HideOnMobile {
    display: none !important;
  }

  .showOnMobile {
    display: table-cell !important;
  }

  .container {
    .content {
      .tableCell {
        padding-block: 11px !important;
      }

      .Each {
        padding: 16px;

        h4 {
          padding-bottom: 0 !important;
        }
      }
    }

    .buttonWrapper {
      padding-bottom: 180px;

      > * {
        width: 100%;
      }
    }

    .summary {
      display: none !important;
    }

    .footer {
      margin-left: 0;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 30px;
      padding-inline: 18px;

      .top {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-bottom: none;
        padding-bottom: 0;

        > * {
          justify-content: space-between;
          width: 100%;

          .inputWrapper {
            input {
              width: 80px;
            }
          }

          .inputSelectWrapper {
            display: none;
          }

          .inputSelectModal {
            display: flex;

            input {
              width: 100px !important;
            }
          }

          .descWrapper {
            span {
              &:nth-child(2) {
                padding-right: 16px !important;
              }
            }
          }
        }
      }

      .bottom {
        span {
          font-size: 14px;
        }

        > div {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .buttonsWrapper {
    gap: 12px;

    .right {
      flex-direction: column;
    }
  }
}
