@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  border: 1px solid $NEUTRAL_30;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
  // width: 500px;

  &.multiple {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $NEUTRAL_100;
    padding-bottom: 6px;
    text-align: left;
  }

  .content {
    > button {
      display: flex;
      align-items: center;
      white-space: nowrap;
      background-color: transparent;
      border: none;
      gap: 8px;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 8px;
      padding-inline: 4px;
      position: relative;
      .subMenuBox {
        position: absolute;
        top: 15px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-Stroke, #ededed);
        background: var(--Neutral-10, #fff);
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
        padding: 12px 12px 4px 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 3;
        gap: 8px;
        left: -225px;
        width: 225px;
        > span {
          color: var(--Neutral-100, #0a0a0a);

          /* Text M/Regular */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          border-bottom: 1px solid #ededed;
          padding-bottom: 8px;
          width: 100%;
          text-align: left;
          &.disabled {
            color: $NEUTRAL_50 !important;
            &:hover {
              color: $NEUTRAL_50 !important;
            }
          }
          &:hover {
            color: #1571de;
          }
          &.noBorder {
            border: unset;
          }
        }
      }
      &:disabled {
        > * {
          color: $NEUTRAL_50 !important;
        }
      }

      // &:not(:first-child) {
      // }

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL_30;
      }

      &:hover {
        background-color: $NEUTRAL_20;
        // >span {
        //   // font-weight: 500;
        // }
      }

      > span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: block;
      }
    }
  }
}

// @media screen and (max-width : 768px) {

//   .container {

//   }
// }
