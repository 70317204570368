@import "../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  padding: 24px;

  &.error {
    border-color: #f5610d !important;
  }

  .cIButton {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $PRIMARY_MAIN;
    color: $NEUTRAL-10;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;

    > span {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
    }
  }

  .deleteContactWrapper {
    display: grid;
    place-items: center;
  }

  .deleteContact {
    display: grid;
    place-items: center;
    background-color: transparent;
    border: none;
  }

  .odd {
    > td {
      background-color: $PRIMARY_SURFACE !important;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;
    }

    > p {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      color: $NEUTRAL-60;

      > span {
        font-weight: 700;
      }
    }
  }

  .contentTable {
    padding-top: 16px;

    > * {
      z-index: 0;
    }
  }

  .emptyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    > img {
      width: 188px;
      object-fit: contain;
      max-width: 90vw;
    }

    > span {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      color: $NEUTRAL-60;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    background-color: transparent;

    .header {
      flex-direction: column;
      align-items: flex-start;
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }
    }

    .contentTable {
      padding-top: 8px;
    }
  }
}
