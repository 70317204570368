@import "../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  overflow: auto;
  background-color: $NEUTRAL_LIGHTER;

  .leftProceed {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .stepper {
    padding-left: 50px;
  }

  .right {
    width: 100%;
    // padding: 16px;
    padding-right: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .topRight {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      > span {
        border-radius: 50px;
        background: #de7515;
        display: flex;
        padding: 6px 16px;
        align-items: center;
        justify-content: center;
        color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

        /* Text S/Medium */
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 12px;
        cursor: pointer;
        > span {
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .bottomRight {
      display: flex;
      // align-items: center;
      gap: 8px;
      > div {
        min-width: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 8px;
        .icon {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 10px;
          justify-content: center;
          border-radius: 8px;
          background: var(--Primary-Surface, #f5f7fe);
          height: 30px;
          width: 30px;
        }

        .titleBox {
          display: flex;
          flex-direction: column;
          gap: 4px;
          > span:first-child {
            color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

            /* Caption 2/Reguler */
            // font-family: Poppins;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 145.455% */
          }
          > span:last-child {
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text M/Bold */
            // font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }

  &.proceed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .topWrapper {
      margin-inline: unset;
    }
  }

  .topWrapper {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    border-top: 1px solid $NEUTRAL_30;
    max-width: 1440px;
    margin-inline: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .breadcrumbWrapper {
      padding: 24px 30px;
      padding-right: 0px;
      display: flex;
      align-items: center;
      gap: 24px;
      flex-grow: 1;

      > button {
        height: 40px;
        aspect-ratio: 1;
        // background-color: $NEUTRAL_10;
        border-radius: 50%;
        border: none;
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        &.breadcrumbText {
          color: $NEUTRAL_60;
          cursor: pointer;
        }

        &.breadcrumbTextBold {
          color: $NEUTRAL_100;
        }
      }
    }

    .typeWrapper {
      background-color: $NEUTRAL_20;
      border: 1px solid $NEUTRAL_40;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      padding: 8px;
      margin-right: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: calc(100% - 28px);

      .descWrapper {
        display: flex;
        flex-direction: column;

        > span:first-child {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $NEUTRAL_100;
        }

        > p:last-child {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_70;
        }
      }

      .contractIcon {
        color: $PRIMARY_MAIN;
      }
    }
  }

  .stepperWrapper {
    // padding-bottom: 30px;
    border-bottom: 1px solid $NEUTRAL_40;
    max-width: 1440px;
    margin-inline: auto;
  }

  .infoWrapper {
    padding: 8px 30px;
    display: flex;
    align-items: center;
    gap: 24px;

    img {
      width: 32px;
      aspect-ratio: 1;
    }

    > div {
      h3 {
        padding-bottom: 4px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.005em;
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_DARKEST;
      }
    }

    &.complete {
      // background-color: #ecefff;

      h3 {
        color: $PRIMARY_MAIN;
      }
    }

    &.incomplete {
      background-color: #ffe8e3;

      h3 {
        color: $DANGER_MAIN;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    .right {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .typeWrapper {
      display: none !important;
    }

    .breadcrumbWrapper {
      padding: 8px 18px !important;
      padding-right: 0px;
    }

    .stepperWrapper {
      padding-bottom: 14px;
    }

    .infoWrapper {
      padding: 8px 18px;
    }
  }
}
