@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_20;
  display: flex;
  flex-direction: row;
  padding: 16px;
  position: relative;
  width: 100vw;
  height: 100vh;
  gap: 24px;

  .sidebar {
    background-color: $PRIMARY_MAIN;
    width: 76px;
    height: calc(100% - 32px);
    border-radius: 12px;
    position: absolute;
    display: flex;
    flex-direction: column;
    transition: 200ms;
    justify-content: space-between;
    z-index: 3;

    .top {
      display: flex;
      padding: 8px 0;
      flex-direction: column;

      .iconWrapper {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        width: 27px;
        height: 60px;

        img {
          flex-shrink: 0;
        }
      }

      .navbar {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .menuWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          padding: 11px;
          cursor: pointer;

          .button {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-start;

            .icon {
              color: $NEUTRAL_10;
              flex-shrink: 0;
            }

            .text {
              transition: all 200ms;
              color: $NEUTRAL_10;
              font-size: 16;
              font-weight: 500;
            }
          }

          .arrow {
            color: $NEUTRAL_10;
          }
        }

        .selectedMenuWrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 8px;
          gap: 10px;
          padding: 11px;
          cursor: pointer;
          background-color: $NEUTRAL_10;

          .button {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-start;

            .icon {
              color: $PRIMARY_MAIN;
              flex-shrink: 0;
            }

            .text {
              transition: all 200ms;
              color: $PRIMARY_MAIN;
              font-size: 16;
              font-weight: 500;
            }
          }

          .arrow {
            color: $NEUTRAL_10;
          }
        }
      }
    }

    .bottom {
      padding: 8px;

      .helpdesk {
        background-color: #005cca;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 8px;
        gap: 16px;

        .icon {
          height: 40px;
          width: 40px;
          border-radius: 20px;
          background-color: $NEUTRAL_10;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .helpdeskButtonWrapper {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          gap: 10px;
          transition: all 200ms ease;

          .information {
            display: flex;
            flex-direction: column;
            gap: 4px;
            text-align: center;

            .title {
              font-size: 16px;
              font-weight: 600;
              color: $NEUTRAL_10;
            }

            .text {
              font-size: 13px;
              font-weight: 400;
              color: $NEUTRAL_20;
            }
          }

          .pressable {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $NEUTRAL_10;
            border-radius: 8px;
            padding: 8px 12px;
            cursor: pointer;

            .label {
              font-size: 14px;
              font-weight: 400;
              color: $NEUTRAL_10;
            }

            .arrow {
              color: $NEUTRAL_10;
            }
          }

          .pressable:hover {
            background-color: $PRIMARY_HOVER;
          }
        }
      }
    }
  }

  .sidebar:hover {
    width: 281px;
    transition: 200ms;

    .top {
      .iconWrapper {
        justify-content: flex-start;
        width: 77px;
      }

      .navbar {
        padding-top: 16px;

        .menuWrapper {
          .button {
            .text {
              transition: all 200ms;
              color: $NEUTRAL_10;
            }
          }
        }

        .selectedMenuWrapper {
          .button {
            .text {
              transition: all 200ms;
              color: $PRIMARY_MAIN;
            }
          }
        }

        .menuWrapper:hover {
          background-color: $PRIMARY_HOVER;
        }

        .submenuWrapper {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          transition: 200ms;

          .submenu {
            display: flex;
            flex-direction: row;
            gap: 16px;
            flex-shrink: 0;
            height: 40px;
            transition: 1000ms all;

            .button {
              width: 100%;

              .menuWrapper {
                padding: 8px;
                display: flex;
                justify-content: flex-start;
                border-radius: 8px;
                width: 100%;

                .text {
                  font-size: 14px;
                  font-weight: 400;
                  color: $NEUTRAL_10;
                }
              }

              .selectedMenuWrapper {
                padding: 8px;
                display: flex;
                justify-content: flex-start;
                background-color: $NEUTRAL_10;
                border-radius: 8px;
                width: 100%;

                .text {
                  font-size: 14px;
                  font-weight: 400;
                  color: $PRIMARY_MAIN;
                }
              }
            }

            .curveWrapper {
              display: flex;
              position: relative;

              .curve {
                width: 10px;
                height: 15px;
                border: solid 2px $NEUTRAL_10;
                border-color: transparent transparent $NEUTRAL_10 $NEUTRAL_10;
                border-radius: 0px 0px 80px 1000px;
                margin-left: 20px;
                position: absolute;
              }

              .straight {
                height: 44px;
                border: solid 1px $NEUTRAL_10;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  .content {
    margin-left: 100px;
    background-color: $NEUTRAL_10;
    width: 100%;
    border-radius: 12px;
    overflow: auto;
    position: relative;

    .header {
      background-color: $NEUTRAL_10;
      border-radius: 12px 12px 0 0;
      padding: 28px 24px;
      border-bottom: 1px solid #ededed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 82px;
      top: 0;
      z-index: 2;
      position: sticky;
      top: 0;

      .routeNameWrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .routeNameTitle {
          font-size: 20px;
          font-weight: 600;
          color: $NEUTRAL_100;
        }

        .navigationTextWrapper {
          display: flex;
          align-items: center;
          gap: 4px;

          .linkText {
            font-size: 13px;
            font-weight: 500;
            color: $PRIMARY_MAIN;
            text-decoration: underline;
            cursor: pointer;
          }

          .arrowIcon {
            color: $NEUTRAL_60;
          }

          .text {
            font-size: 13px;
            font-weight: 500;
            color: $NEUTRAL_60;
          }
        }
      }

      .iconWrapper {
        display: none;
      }

      .profileWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        cursor: pointer;

        .profilePicture {
          height: 38px;
          width: 38px;
          border-radius: 19px;
          background-color: $NEUTRAL_60;
          overflow: clip;

          > img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1;
          }
        }

        .userInformation {
          display: flex;
          flex-direction: column;

          .username {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_100;
          }

          .jobTitle {
            font-size: 12px;
            font-weight: 400;
            color: $NEUTRAL_60;
          }
        }

        .iconWrapper {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          .icon {
            color: $PRIMARY_MAIN;
          }
        }

        .dropdownWrapper {
          position: absolute;
          top: 50px;
          right: 0;
        }
      }
    }

    .inactiveMenuModalWrapper {
      display: none;
    }

    .menuModalWrapper {
      display: none;
    }
  }
}

@media (width <=768px) {
  .container {
    padding: 0;

    .sidebar {
      display: none;
    }

    .content {
      margin-left: 0;
      transition: 200ms;
      border-radius: 0;

      .header {
        border-radius: 0;
        width: 100%;
        position: fixed;

        .routeNameWrapper {
          display: none;
        }

        .iconWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .profileWrapper {
          gap: 4px;

          .userInformation {
            display: none;
          }
        }
      }

      .body {
        margin-top: 82px;
        z-index: 0;
      }

      .inactiveMenuModalWrapper {
        overflow: hidden;
        width: 100%;
        max-height: 0;
        transition: 100ms;
        opacity: 0;
      }

      .menuModalWrapper {
        background-color: rgba(0, 0, 0, 0.3);
        height: calc(100% - 82px);
        position: absolute;
        top: 82px;
        width: 100%;
        display: inline;
        transition: 200ms;
        z-index: 2;

        .menuModal {
          background-color: $NEUTRAL_10;
          position: fixed;
          display: flex;
          flex-direction: column;
          padding: 8px 16px;
          width: 100%;
          gap: 4px;

          .navigation {
            border-radius: 8px;
            padding: 8px 11px;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;

            .selectedButton {
              gap: 10px;
              display: flex;
              align-items: center;

              .icon {
                color: $PRIMARY_MAIN;
              }

              .text {
                font-size: 14px;
                font-weight: 400;
                color: $PRIMARY_MAIN;
              }
            }

            .button {
              gap: 10px;
              display: flex;
              align-items: center;

              .icon {
                color: $NEUTRAL_100;
              }

              .text {
                font-size: 14px;
                font-weight: 400;
                color: $NEUTRAL_100;
              }
            }

            .arrowWrapper {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              .arrow {
                color: $NEUTRAL_100;
              }
            }
          }

          .submenuWrapper {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            transition: 200ms;

            .submenu {
              display: flex;
              flex-direction: row;
              gap: 16px;
              flex-shrink: 0;
              height: 40px;
              transition: 1000ms all;

              .button {
                width: 100%;

                .menuWrapper {
                  padding: 8px;
                  display: flex;
                  justify-content: flex-start;
                  border-radius: 8px;
                  width: 100%;

                  .text {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                  }
                }

                .selectedMenuWrapper {
                  padding: 8px;
                  display: flex;
                  justify-content: flex-start;
                  background-color: $NEUTRAL_10;
                  border-radius: 8px;
                  width: 100%;

                  .text {
                    font-size: 14px;
                    font-weight: 400;
                    color: $PRIMARY_MAIN;
                  }
                }
              }

              .curveWrapper {
                display: flex;
                position: relative;

                .curve {
                  width: 10px;
                  height: 15px;
                  border: solid 2px #000;
                  border-color: transparent transparent #000 #000;
                  border-radius: 0px 0px 80px 1000px;
                  margin-left: 20px;
                  position: absolute;
                }

                .straight {
                  height: 44px;
                  border: solid 1px #000;
                  margin-left: 20px;
                }
              }
            }
          }

          .subNavigation {
            cursor: pointer;
            overflow: hidden;
            width: 100%;
            max-height: 0;
            // transition: 100ms;
            opacity: 0;
          }

          .activeSubNavigation {
            padding: 8px 11px;
            cursor: pointer;
            max-height: 9999px;
            transition: 200ms;
            opacity: 100%;

            .text {
              font-size: 14px;
              font-weight: 400;
              color: $NEUTRAL_100;
            }
          }

          .activeSubNavigation:hover {
            background-color: #f5f5f5;
            border-radius: 8px;
          }

          .selectedSubNavigation {
            .text {
              color: $PRIMARY_MAIN;
            }
          }

          .navigation:hover {
            background-color: $NEUTRAL_20;
          }
        }
      }
    }
  }
}
