@import "../../../../../../../theme/variables.scss";
@import "../../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .dCity {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.dEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > img {
    height: 59px;
    object-fit: contain;
  }

  > span {
    font-size: 12px;
  }
}
.dCard {
  background-color: $NEUTRAL-20;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > span {
    font-size: 14px;
    font-weight: 500;
  }

  .dContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.duCard {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $NEUTRAL-10;
  padding: 6px 10px;
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;

  > img {
    width: 32px;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  > div {
    display: flex;
    flex-direction: column;

    > span {
      font-size: 14px;
    }

    > p {
      font-size: 12px;
      color: $NEUTRAL-60;
    }
  }
}

.pCard {
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;
  overflow: clip;

  .pHeader {
    background-color: $PRIMARY_SURFACE;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    .leftPHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-grow: 1;
      > span {
        font-size: 20px;
        font-weight: 600;
        color: $PRIMARY_MAIN;
      }
    }

    .dedicatedRO {
      display: flex;
      align-items: center;
      gap: 8px;
      .Ro {
        display: flex;
        align-items: center;
        gap: 8px;
        > img {
          width: 28px;
          object-fit: contain;
        }
        > p {
          font-size: 14px;
          font-weight: 500;
        }
      }

      > span {
        font-size: 14px;
      }
    }
  }

  .pContent {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .pEach {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Bold */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
      }

      .qContainer {
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;

        .qGrid {
          display: grid;
          grid-template-columns: 320px 1fr;
          gap: 16px;
        }

        .qHeader {
          background-color: $NEUTRAL-20;
          padding: 16px;

          > span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .qContent {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .qEach {
            > span {
              font-size: 14px;

              &:nth-child(2) {
                font-weight: 500;
              }
            }
          }
        }
      }

      .cWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .cHeader {
          display: flex;
          align-items: center;
          gap: 8px;

          > span {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.aCard {
  background-color: $NEUTRAL-20;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > span {
    font-size: 14px;
    font-weight: 700;
  }

  .aWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      > span {
        font-size: 14px;

        &:nth-child(2) {
          font-weight: 500;
        }
      }
    }
  }
}

.cEach {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.BoxBPU {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 6px;
  background: var(--Color-Neutal-Neutral-20, #f5f5f5);
  padding: 16px;
  > span {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text M/Bold */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }

  .bpjsBox {
    display: flex;
    // align-items: center;
    gap: 16px;
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 6px;
      border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
      padding: var(--spacing-12px, 12px);
      > span {
        color: #000;

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        padding-bottom: 2px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span:first-child {
          color: #000;

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          width: 100%;
        }
        > span:last-child {
          color: #000;
          text-align: right;

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .BoxBPU {
    .bpjsBox {
      flex-direction: column;
    }
  }

  .pCard {
    border: 1px solid $NEUTRAL-40;
    border-radius: 8px;
    overflow: clip;

    .pHeader {
      flex-direction: column;
      align-items: flex-start;
    }

    .pContent {
      .pEach {
        .qContainer {
          .qGrid {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
          }

          .qHeader {
            > span {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              &:nth-child(2) {
                display: flex;
                justify-content: flex-end;
              }
            }
          }

          .qContent {
            .qEach {
              > span {
                &.qName {
                  width: 100%;
                }
                &:nth-child(2) {
                  font-weight: 500;
                  width: 100%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: flex-end;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .pCard {
    .pHeader {
      .dedicatedRO {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .aCard {
    .aWrapper {
      gap: 20px;
      > div {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
