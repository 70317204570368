@import "../../../../theme/variables.scss";

.container {
  width: 400px;

  &.wide {
    width: 658px;
  }

  .header {
    background-color: $PRIMARY_SURFACE;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;

    > img {
      height: 146px;
    }

    > h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $PRIMARY_MAIN;
    }
  }

  .content,
  .newContent {
    background-color: $NEUTRAL-10;
    border-radius: 0 0 8px 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      > button {
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;

        &:first-child {
          background-color: $NEUTRAL-10;
          color: $PRIMARY_MAIN;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }

    .ncHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      > button {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        border: none;
        font-size: 14px;

        &:disabled {
          color: $NEUTRAL-50;
        }
      }

      > span {
        font-size: 12px;
        color: $NEUTRAL-60;
      }
    }

    .first {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .field {
        .generalRO {
          display: flex;
          align-items: center;
          gap: 4px;
          padding-top: 4px;

          > span {
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }

    .second {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > span {
        font-size: 14px;
        font-weight: 700;
      }

      .qWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        // max-height: 200px;
        // overflow: auto;

        .eachQ {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          border-bottom: 1px solid #ededed;
          padding-bottom: 20px;
          &.eachQNoBorder {
            padding-bottom: 0px;
            border-bottom: unset;
          }
          .left {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            .searchQ {
              width: 100%;
            }
          }
          .bWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            top: 10px;

            > button {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
              border: none;
              color: $DANGER_MAIN;

              &:disabled {
                color: $NEUTRAL-50;
              }
            }
          }
        }
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 14px;
        color: $PRIMARY_MAIN;
        background-color: $NEUTRAL-10;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        padding: 8px;
      }
    }
  }
}

// .detailQualification {
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   .bWrapper {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // padding-bottom: 12px;

//     > button {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       background-color: transparent;
//       border: none;
//       color: $DANGER_MAIN;

//       &:disabled {
//         color: $NEUTRAL-50;
//       }
//     }
//   }
// }

@media screen and (max-width: 768px) {
  .container {
    .content,
    .newContent {
      .second {
        .qWrapper {
          .eachQ {
            gap: 16px;
            .left {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
