@import "../../../../../theme/variables.scss";

.background {
  .container {
    width: 705px;
    background: $NEUTRAL_10;
    border-radius: 8px;

    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header,
    .normal,
    .buttonsWrapper {
      padding-inline: 24px;
    }

    .header {
      display: flex;
      align-items: center;
      padding-top: 24px;

      h3 {
        display: flex;
        align-items: center;
        flex-grow: 1;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_100;
          padding-right: 10px;
        }
      }

      button {
        background-color: transparent;
        border: 0 solid $NEUTRAL-100;
      }

      .searchBarWrapper {
        width: 250px;
      }
    }

    .normal {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: auto;
      max-height: 420px;
      min-height: 420px;
      //   height: 100px;
      .headerLocation {
        width: 100%;
        .loc {
          display: flex;
          padding: 8px var(--spacing-12px, 12px);
          align-items: center;
          gap: 16px;
          //   flex: 1 0 0;
          width: 100%;
          border-radius: 8px;
          border: 1px solid var(--Primary-Main, #1571de);
          background: var(--Color-Neutal-Neutral-20, #f5f5f5);
          > div:first-child {
            width: 24px;
            height: 24px;
            border-radius: 50px;
            background: var(--Color-Neutal-Neutral-10, #fff);
            display: flex;
            padding: var(--border-radius-04, 4px);
            flex-direction: column;
            align-items: center;
          }

          > div:last-child {
            display: flex;
            flex-direction: column;
            gap: 2px;
            > span:first-child {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

              /* Caption 2/Reguler */
              //   font-family: Poppins;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 145.455% */
            }
            > span:last-child {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text M/Medium */
              //   font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
            }
          }
        }
      }

      .detailWrapper {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .detailBox {
          flex-grow: 1;
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          background-color: $NEUTRAL_20;
          border-radius: 8px;
          padding: 8px 4px 8px 4px;
          cursor: pointer;

          &.active {
            border: 1px $PRIMARY_MAIN solid;
          }

          .contentWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            .contentDetailBox {
              display: flex;
              width: 100%;
              border-radius: 4px;
              flex-direction: column;

              span {
                &:first-child {
                  font-family: "Poppins";
                  font-size: 12px;
                  font-weight: 400;
                  color: $NEUTRAL-60;
                }
                &:last-child {
                  font-family: "Poppins";
                  font-size: 14px;
                  font-weight: 500;
                  color: $NEUTRAL-100;
                }
              }
            }
          }
          .iconDash {
            display: flex;
            width: 40px;
            height: 40px;
            padding: 8px;
            background-color: $NEUTRAL_20;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        gap: 16px;

        > h4 {
          flex-grow: 1;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          padding: 8px;
          border-radius: 6px;
          background-color: $NEUTRAL-10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .topWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          font-size: 16px;
          font-weight: 600;
          color: $NEUTRAL-100;
        }

        .pageNumber {
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 400;
          color: $NEUTRAL-60;
        }
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .titleWrapper {
          display: flex;
          align-items: center;
          padding-top: 16px;

          > h4 {
            flex-grow: 1;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
          }
        }

        .topBarWrapper {
          display: flex;
          align-items: center;
          gap: 16px;

          .backBox {
            flex-grow: 1;
            display: flex;
            gap: 12px;

            font-size: 14px;
            font-weight: 400;
            color: $PRIMARY_MAIN;
            cursor: pointer;
          }

          .searchBarBox {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $NEUTRAL_100;
          padding-bottom: 8px;
        }

        .tableWrapper {
          height: 100px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          overflow-y: scroll !important;

          .tableContainer {
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            flex-grow: 1;

            .table {
              border-collapse: separate;

              .tableHead {
                position: sticky;
                top: 0;
                background-color: $NEUTRAL-10;
                z-index: 1;
                overflow-y: hidden;

                .tableHeadRow {
                  .tableHeadCell {
                    padding: 14px 16px !important;
                  }
                }
              }

              .tableBody {
                .tableRow {
                  &.selected {
                    background-color: $PRIMARY_SURFACE;
                  }

                  .tableCell {
                    padding: 12px 16px !important;

                    .stackedProfile {
                      background-color: transparent;
                      border: none;
                      display: flex;
                      align-items: center;
                      gap: 8px;
                    }
                  }

                  .buttonIcon {
                    display: grid;
                    place-items: center;
                    background-color: transparent;
                    border: none;
                    color: $PRIMARY_MAIN;

                    &.expand {
                      position: relative;
                    }

                    &:disabled {
                      color: $NEUTRAL_60;
                    }

                    > img {
                      height: 24px;
                      aspect-ratio: 1;
                      z-index: 0;
                    }
                  }
                }
              }
            }

            .bottom {
              width: 100%;
              height: 24px;
            }
          }
        }

        .emptyWrapper {
          flex-grow: 1;
          height: 476px;
        }

        .emptyDialogWrapper {
          flex-grow: 1;
          height: 200px;
        }

        .paginationWrapper {
          border-top: 1px solid $NEUTRAL_50;
        }

        .checkBoxWrapper {
          display: grid;
          align-items: center;
          grid-template-columns: 50% 50%;
          gap: 8px;

          .containerSend {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border: 1px solid $NEUTRAL-40;
            border-radius: 12px;
          }

          .containerSendDisable {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border: 1px solid $NEUTRAL-40;
            border-radius: 12px;
            background-color: $NEUTRAL-20;
            color: $NEUTRAL_60;
          }

          .containerSendEmailError {
            display: flex;
            // justify-content: center;
            align-items: center;
            color: $PRIMARY_MAIN;
            gap: 8px;
          }
        }
      }
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding-top: 16px;
      padding-bottom: 24px;
    }

    .buttonsWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      button {
        flex-grow: 1;
        padding: 8px 16px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        transition: all 0.3s;

        &.submitOnly {
          flex-grow: 0;
        }

        &:first-child {
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          border: none;
          color: $NEUTRAL_10;

          &.process {
            background-color: $PRIMARY_SOFT;
          }

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }

    .errorWrapper {
      display: flex;
      padding-left: 24px;
      padding-right: 24px;
      color: $DANGER_MAIN;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .background {
    .container {
      width: 100%;
      .normal {
        .detailWrapper {
          flex-direction: column;
          .detailBox {
            width: 100%;
          }
        }
      }
    }
  }
}
