.bodyFilter {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    max-height: 100vh;
    min-height: 100vh;
    border-radius: 12px 0px 0px 12px;
    background: var(--Color-Neutal-Neutral-10, #fff);
    width: 400px;
    position: relative;
    animation: slideLeft 0.5s ease-out forwards; /* animasi slide ke kiri */
    transform: translateX(100%); /* awalnya sembunyikan ke kanan */

    display: flex;
    flex-direction: column; /* Tambahkan ini untuk membuat konten column */
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 16px;
      border-bottom: 1px solid #e0e0e0;
      > span:first-child {
        color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

        /* Heading 6/SemiBold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.12px;
      }
      > span:last-child {
        color: red;

        /* Heading 6/SemiBold */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.12px;
        cursor: pointer;
      }
    }

    .filterContent {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 16px 24px 16px;

      flex: 1; /* Agar bagian ini bisa scroll jika konten melebihi tinggi */
      overflow-y: auto; /* Menambahkan scroll di bagian filterContent */

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 16px;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        > div {
          width: 100%;
          height: 44px;
          &.noHeight {
            height: unset;
          }
          .dateBox {
            display: flex;
            align-items: center;
            gap: 4px;
            width: 100%;
            > div {
              width: 100%;
            }
          }
        }
      }
    }

    .buttonSection {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      width: 100%;
      background: var(--Color-Neutal-Neutral-10, #fff);
      box-shadow: 0px -4px 24px 0px rgba(132, 132, 132, 0.15);
      border-radius: 0px 0px 0px 8px;
      border-top: 1px solid #e0e0e0;

      > div {
        width: 100%;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        display: flex;
        padding: var(--spacing-12px, 12px);
        justify-content: center;
        align-items: center;
        // gap: 8px;
        // align-self: stretch;
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
        &.disabled {
          background: #77a6e0;
        }
      }
      > div:hover {
        background: rgb(41, 106, 187);
        &.disabled {
          background: #77a6e0;
        }
      }
    }
  }

  @keyframes slideLeft {
    to {
      transform: translateX(0); /* geser ke kiri sampai muncul */
    }
  }
}

@media screen and (max-width: 450px) {
  .bodyFilter {
    > div {
      width: 100%;
      max-height: 450px;
      min-height: 450px;
      border-radius: 12px 12px 0px 0px;
      overflow: auto;

      position: fixed;
      bottom: 0;
      animation: slideUp 0.5s ease-out forwards; /* Animation sliding from bottom to top */
      transform: translateY(100%); /* Initially hide below */
      .header {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 2;
      }
      .buttonSection {
        position: sticky;
        bottom: 0;
        z-index: 1;
      }
    }

    @keyframes slideUp {
      to {
        transform: translateY(0); /* Slide up to reveal */
      }
    }
  }
}
