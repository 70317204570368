@import "../../../../../../theme/variables.scss";

.container {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    width: 100%;
    // max-height: 450px;
    // min-height: 450px;
    border-radius: 0px 0px 12px 12px;
    overflow: auto;
    position: fixed;
    top: 0;
    animation: slideRight 0.5s ease-out forwards; /* animasi slide ke kanan */
    transform: translateX(-100%); /* awalnya sembunyikan di kiri */
    background: var(--Color-Neutal-Neutral-10, #fff);
    max-height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .headerBox {
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 3;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        background: var(--Primary-Main, #1571de);
        padding: 10px 16px;
        .left {
          display: flex;
          align-items: center;
          gap: 10px;
          .iHLogo {
            display: flex;
            width: 32px;
            height: 32px;
            padding: 6.095px;
            justify-content: center;
            align-items: center;
            border-radius: 45.714px;
            background: var(--Primary-Soft, #e1edfe);
          }

          > span {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

            /* Text M/Bold */
            //   font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
          }
        }

        .iconCross {
          width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      .searchBox {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 10px 16px;
        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          background-color: $NEUTRAL-10;
          padding: 8px;
          border-radius: 8px;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
          width: 100%;
        }

        .searchWrapper {
          height: 44px;
        }
      }
    }

    .emptyHandler {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      flex-grow: 1;
      > img {
        width: 183px;
        object-fit: contain;
      }
      > span {
        text-align: center;
        font-size: 14px;
        color: $NEUTRAL-60;
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .iList {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 12px 16px;

        .each {
          padding: 8px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          transition: background-color ease 0.3s;

          &:hover {
            background-color: $PRIMARY_SURFACE;
            color: $PRIMARY_MAIN;
          }

          &.active {
            background-color: $PRIMARY_MAIN;
            color: $NEUTRAL-10;
          }

          > span {
            flex-grow: 1;
            font-size: 14px;
          }
        }
      }

      .iListPosition {
        display: flex;
        flex-direction: column;
        gap: 4px;

        padding: 12px 16px;

        .each {
          .eHeader {
            padding: 8px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            cursor: pointer;
            transition: background-color ease 0.3s;

            .ehTop {
              display: flex;
              align-items: center;
              gap: 4px;

              > span {
                flex-grow: 1;
                font-size: 14px;
                font-weight: 500;
              }

              .cityAmount {
                padding: 3px 6px;
                border-radius: 6px;
                font-size: 11px;
                background-color: $PRIMARY_DISABLED;
              }

              > button {
                background-color: transparent;
                border: none;
                display: grid;
                place-items: center;
              }
            }

            .ehBottom {
              display: flex;
              align-items: center;
              gap: 2px;
              color: $NEUTRAL-70;

              > span {
                font-size: 11px;
                flex-grow: 1;
              }

              > button {
                background-color: transparent;
                border: none;
                display: grid;
                place-items: center;
                color: $PRIMARY_DISABLED;
              }
            }

            &:hover {
              background-color: $PRIMARY_SURFACE;
              color: $PRIMARY_MAIN;
            }

            &.active {
              background-color: $PRIMARY_MAIN;
              color: $NEUTRAL-10;

              .ehTop {
                .cityAmount {
                  background-color: $NEUTRAL-10;
                  color: $NEUTRAL-100;
                }

                > button {
                  color: $NEUTRAL-10;
                }
              }

              .ehBottom {
                color: $NEUTRAL-10;
              }
            }
          }

          .eContent {
            padding-top: 8px;
            padding-left: 24px;

            > div {
              &:first-child {
                border-left: 1px solid $NEUTRAL-50;
                padding-left: 8px;
              }

              &.active {
                border-color: $PRIMARY_MAIN;
              }

              &.eSearchWrapper {
                padding-bottom: 8px;
              }

              &.ecList {
                .ecCity {
                  border-left: 1px solid $NEUTRAL-50;
                  padding-left: 8px;

                  > div {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    border-radius: 8px;
                    cursor: pointer;
                    padding: 8px;

                    &[is-completed="true"] {
                      color: $PRIMARY_MAIN !important;
                    }
                    &[is-completed="false"] {
                      color: $NEUTRAL-60;

                      > span {
                        color: $NEUTRAL-100;
                      }
                    }
                    &[is-applied="false"] {
                      > * {
                        color: $NEUTRAL-50 !important;
                      }
                    }

                    > span {
                      flex-grow: 1;
                      font-size: 14px;
                    }
                  }

                  &.active {
                    border-color: $PRIMARY_MAIN;

                    > div {
                      background-color: $PRIMARY_SOFT;

                      > span {
                        color: $PRIMARY_MAIN;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideRight {
  to {
    transform: translateX(0); /* Geser ke kanan hingga muncul */
  }
}
