@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 443px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  border-radius: 8px;
  gap: 2px;

  h3 {
    padding-top: 16px;
    font-size: 24px;
    font-weight: 600;
  }

  > span {
    font-size: 13px;
    color: #878787;
    padding-bottom: 24px;
    text-align: center;
  }

  > button {
    width: 100%;
    border: 1px solid $PRIMARY_MAIN;
    color: $PRIMARY_MAIN;
    background-color: transparent;
    padding: 12px 16px;
    font-size: 14px;
    border-radius: 8px;
  }

  .notes {
    background-color: $PRIMARY_SURFACE;
    padding: 8px 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    > * {
      &:first-child {
        flex-shrink: 0;
      }
    }

    > p {
      font-size: 12px;

      > span {
        font-style: italic;
        font-weight: 700;
      }
    }
  }
}
