@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  .labelCustom {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > span:first-child {
      text-align: right;
      font-weight: 400;
      font-size: 10px;
      color: $NEUTRAL_60;
    }
    > span:last-child {
      width: 120px;
      text-align: right;
      font-weight: 500;
      font-size: 12px;
      color: $NEUTRAL_100;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
