@import "../../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  padding: 24px;

  .Paper {
    border-radius: 8px !important;
    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
      0px 3px 5px rgba(9, 30, 66, 0.2);
    padding: 0;
  }

  li {
    padding: 12px;
    position: relative;

    &:last-child {
      .separator {
        display: none;
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .separator {
      position: absolute;
      height: 1px;
      width: calc(100% - 24px);
      left: 12px;
      bottom: 0;
      background-color: $NEUTRAL_50;
    }
  }

  .labelsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    padding-top: 6px;

    .label {
      background: $NEUTRAL_10;
      border: 1px solid $NEUTRAL_40;
      border-radius: 4px;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 16px;
        color: $NEUTRAL_90;
      }

      button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 16px;
      }
    }
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $NEUTRAL_100;
  }

  .content {
    padding-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 16px;

    .title {
      font-size: 13px;
      font-weight: 500;
      color: $NEUTRAL_100;
      padding-bottom: 4px;
      span {
        color: $DANGER_MAIN;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    background-color: transparent;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
    }

    .content {
      padding-top: 8px;
      grid-template-columns: 1fr;
    }
  }
}
