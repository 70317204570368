@import "../../../../../theme/variables.scss";

.container {
  // max-width: 1350px;
  max-width: 1200px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-inline: 30px;
  padding-bottom: 40px;
  padding-top: 40px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    border-bottom: 1px solid #ece6e6;
    .topHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      > div:first-child {
        img {
          width: 95px;
          height: 41px;
        }
      }
      > div:last-child {
        display: flex;
        align-items: center;
        gap: 8px;
        > div:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 46px;
          border-radius: 50px;
          background: #f3f5f7;
          > img {
            width: 20px;
            height: 30px;
          }
        }

        > div:last-child {
          display: flex;
          flex-direction: column;
          gap: 4px;
          > span:first-child {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

            /* Caption 1/Reguler */
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
          > span:last-child {
            color: #000;

            /* Heading 7/SemiBold */
            // font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 30px */
            letter-spacing: 0.1px;
          }
        }
      }
    }

    .stepControllerWrapper {
      display: flex;

      > *:nth-child(2) {
        flex-grow: 1;
        overflow: auto;
      }

      button {
        display: grid;
        place-items: center;
        background-color: #f4f4f4;
        height: 46px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: none;
        color: #cfcfcf;
      }
    }
  }

  .mainWrapper {
    overflow: auto !important;
    height: 100%;
    // padding-top: 16px;
    padding-bottom: 50px;
    .info {
      border-radius: 8px;
      position: relative;
      overflow: clip;
      margin-top: 16px;

      > img {
        position: absolute;
        z-index: 0;

        &.big {
          width: 149px;
          object-fit: contain;
        }

        &.small {
          width: 89px;
          object-fit: contain;
        }

        &.c1 {
          bottom: 75%;
          left: 25%;
          transform: rotate(-16deg);
        }

        &.c2 {
          bottom: 75%;
          right: 3%;
          transform: rotate(65deg);
        }

        &.c3 {
          top: 55%;
          right: 12%;
          transform: rotate(-90deg);
        }
      }

      > div {
        display: flex;
        align-items: center;
        gap: 16px;
        &:nth-child(1) {
          background: #fff1ce;
          padding: 12px;
          > img {
            position: absolute;
            z-index: 0;

            &.big {
              width: 149px;
              object-fit: contain;
            }

            &.small {
              width: 89px;
              object-fit: contain;
            }

            &.c1 {
              bottom: 75%;
              left: 25%;
              transform: rotate(-16deg);
            }

            &.c2 {
              bottom: 75%;
              right: 3%;
              transform: rotate(65deg);
            }

            &.c3 {
              top: 55%;
              right: 12%;
              transform: rotate(-90deg);
            }
          }
        }

        &:nth-child(2) {
          padding: 12px;
          background: #fff6e0;
          > img {
            position: absolute;
            z-index: 0;

            &.small {
              width: 89px;
              object-fit: contain;
            }

            &.c3 {
              top: 70%;
              right: 12%;
              transform: rotate(-90deg);
            }
          }
        }
        .logo {
          z-index: 1;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border-radius: 60px;
          background: var(--Alert-Main, #e9b62f);
          &.none {
            background: unset;
          }
        }
        .desc {
          display: flex;
          flex-direction: column;
          gap: 4px;
          z-index: 1;
          > span:first-child {
            color: var(--Neutral-90, #404040);

            /* Text L/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }

          > span:last-child {
            color: var(--Neutral-80, #616161);

            /* Text M/Regular */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }

        .notes {
          z-index: 1;
          > span:first-child {
            color: var(--Neutral-90, #404040);

            /* Text L/Regular */
            font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
          }

          > span:last-child {
            color: var(--Neutral-90, #404040);

            /* Text L/Regular */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }

    .errorBox {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: #ffe5e5;
      padding: 12px;
      margin-top: 16px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
      }
      > span {
        color: var(--Danger-Main, #ff3e13);

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 32px 0px;
      .next {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        cursor: pointer;
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .mainWrapper {
      padding-bottom: 100px;
    }
  }
}
