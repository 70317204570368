.containerModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  width: 575px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span {
        // overflow: hidden;
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
        // text-overflow: ellipsis;

        /* Heading 6/SemiBold */
        // font-family: Poppins;
        font-size: calc(16px + (24 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.12px;
      }

      .iconCross {
        cursor: pointer;
      }
    }

    .bottom {
      color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

      /* Text S/Reguler */
      //   font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
  }

  .documentBox {
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    padding: 12px;
    .iconDocument {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
    }

    > span {
      color: #000;
      //   text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .dropdownOptional {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
  .buttonSection {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    width: 100%;
    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
    }
    > div:first-child {
      border-radius: 8px;
      border: 1px solid var(--Primary-Main, #1571de);
      background: var(--Color-Neutal-Neutral-10, #fff);
      color: var(--Primary-Main, #1571de);
      text-align: center;

      /* Text M/Reguler */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
    }

    > div:last-child {
      border-radius: 8px;
      background: var(--Primary-Main, #1571de);
      color: var(--Color-Neutal-Neutral-10, #fff);
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
      &.disabled {
        background: var(--Color-Neutal-Neutral-40, #e0e0e0);
        cursor: unset;
        color: #c2c2c2;
      }
    }
  }
}
