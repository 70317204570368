@import "../../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  padding: 24px;

  .oCard {
    background-color: $NEUTRAL-20;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;

    > span {
      flex-grow: 1;
      font-size: 13px;
      font-weight: 500;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $NEUTRAL_100;
    padding-bottom: 16px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_100;
  }

  .content {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;

    .cutOffSelector {
      width: 50%;
      .cutOffOptions {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        gap: 8px;
        width: 100%;
        > div {
          width: 100%;
          padding: 8px;
          border-radius: 8px;
          border: 1px solid $NEUTRAL-40;
          text-align: center;
          height: 42px;
          display: grid;
          place-items: center;

          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 153.846%;
          cursor: pointer;

          &.active {
            background-color: $PRIMARY_SURFACE;
            border-color: $PRIMARY_MAIN;
            color: $PRIMARY_MAIN;
          }

          &.error {
            border-color: #f5610d !important;
          }
        }
      }
    }

    .top {
      gap: 16px;
      display: flex;
      flex-direction: column;
      > div:first-child {
        width: 100%;
        display: flex;
        align-items: center;

        gap: 16px;
        .selectWrapper {
          width: 100%;
        }
      }
    }

    .middle {
      background-color: $NEUTRAL_20;
      padding: 16px;
      border-radius: 8px;

      .subContent {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        gap: 16px;
        padding-top: 8px;
      }
    }

    .bottom {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
      align-items: start;
      gap: 16px;

      > * {
        width: 100%;
        background-color: $NEUTRAL_20;
        border-radius: 8px;
        padding: 16px;

        .selectSwitchWrapper {
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .registration {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
        }
      }
    }
  }
}

.buttonOption {
  display: flex;
  align-items: center;
  // justify-content: ;
  width: 100%;
  padding-bottom: 16px;
  justify-content: space-between;

  .buttonLeft {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 0px 0px 8px;
    border-top: 1px solid var(--Neutral-60, #9e9e9e);
    border-right: 0.5px solid var(--Neutral-60, #9e9e9e);
    border-bottom: 1px solid var(--Neutral-60, #9e9e9e);
    border-left: 1px solid var(--Neutral-60, #9e9e9e);
    background: var(--Neutral-10, #fff);
    padding: 12px 8px;
    cursor: pointer;
    color: #000;
    text-align: center;

    /* Text S/Reguler */
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    /* 153.846% */
    &.buttonActive {
      background: var(--Primary-Main, #1571de);
      color: var(--Color-Neutal-Neutral-10, #fff);
    }
  }

  .buttonRight {
    cursor: pointer;
    padding: 12px 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 8px 8px 0px;
    border-top: 1px solid var(--Neutral-60, #9e9e9e);
    border-right: 1px solid var(--Neutral-60, #9e9e9e);
    border-bottom: 1px solid var(--Neutral-60, #9e9e9e);
    border-left: 0.5px solid var(--Neutral-60, #9e9e9e);
    background: var(--Neutral-10, #fff);
    color: #000;
    text-align: center;

    /* Text S/Reguler */
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    /* 153.846% */
    &.buttonActive {
      background: var(--Primary-Main, #1571de);
      color: var(--Color-Neutal-Neutral-10, #fff);
    }

    &.disabled {
      cursor: pointer;
      background-color: $NEUTRAL-40;
      color: $NEUTRAL-60;
      border-color: $NEUTRAL-60;
    }
  }
}

.bpuContent {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .warnText {
    color: var(--Neutral-90, #404040);

    /* Text M/Reguler */
    // font-family: Poppins;
    // width: 90%;
    font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
  }

  .radioInputBox {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;

    .radio {
      display: flex;
      align-items: center;
      gap: 5px;

      label {
        color: #000;

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    background-color: transparent;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
    }

    .content {
      padding-top: 8px;
      flex-direction: column;

      .top {
        > div:first-child {
          flex-direction: column;
        }
      }
      .cutOffSelector {
        width: 100%;
        .cutOffOptions {
          flex-direction: column;
          > div {
            background-color: #fff;
          }
        }
      }

      .middle {
        background-color: $NEUTRAL_10;

        .subContent {
          grid-template-columns: 1fr;
        }
      }

      .bottom {
        grid-template-columns: 1fr;

        > * {
          background-color: $NEUTRAL_10;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .bpuContent {
    gap: 16px;

    .radioInputBox {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }

  .buttonOption {
    .buttonLeft {
      border-radius: 8px;
    }

    .buttonRight {
      border-radius: 8px;
    }

    flex-direction: column;
    gap: 8px;
  }
}
