@import "../../../../theme/variables.scss";
$images_info: url("../../../../assets/Images/Info-sign.png");

.Container {
  display: flex;
  justify-content: center;
  // &.ContainerExtend {
  //   justify-content: flex-start;
  // }
  .ContractSignContainer {
    width: 808px;
    display: flex;
    padding-block: 25px;
    flex-direction: column;
    gap: 24px;
    &.width {
      width: unset;
    }

    .informationBox {
      display: flex;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      // background: var(--Primary-Surface, rgba(21, 113, 222, 0.1));
      padding: 12px;
      background-image: $images_info;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 60px;
        background: var(--Primary-Disable, #b1d0f4);
      }

      > div:last-child {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
        > span:first-child {
          color: var(--Neutral-90, #404040);

          /* Text L/SemiBold */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        > span:last-child {
          color: var(--Neutral-90, #404040);

          /* Text L/Regular */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
    }

    .Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      > img {
        height: 41px;
        object-fit: contain;
      }

      .Logo {
        display: flex;
        gap: 16px;
        align-items: center;

        & > :first-child {
          background-color: #f3f5f7;
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          > img {
            width: 26px;
            height: 38px;
          }
        }

        & > :last-child {
          display: flex;
          flex-direction: column;

          > span {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: $NEUTRAL_60;
          }

          > p {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }

    .SectionWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 16px;

      .errorText {
        color: var(--Helper, #f5610d);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      .SignCanvasWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > :first-child {
          font-weight: 600;
          font-size: 20px;
        }

        .Canvas {
          position: relative;
          width: 100%;
          height: 100%;

          .SignCanvas {
            height: 190px;
            width: 100%;
            border: 1px solid #ededed;
            border-radius: 8px;
          }

          .CanvasImage {
            background: $NEUTRAL_10;
            height: 190px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ededed;
            border-radius: 8px;
            width: 100%;

            & > img {
              height: 80%;
              max-width: 80%;
            }
          }

          .CanvasText {
            font-weight: 400;
            font-size: 14px;
            margin-top: 8px;
          }

          .CanvasClear {
            color: $PRIMARY_MAIN;
            right: 16px;
            top: 16px;
            position: absolute;
            cursor: pointer;
          }

          .CanvasAction {
            color: #d42701;

            & > :last-child {
              right: 16px;
              top: 16px;
              position: absolute;
              width: 80px;
              height: 40px;
            }

            & > :first-child {
              left: 16px;
              top: 16px;
              position: absolute;
              cursor: pointer;
            }
          }
        }
      }

      .TitleSection {
        display: flex;
        flex-direction: column;
        gap: 12px;
        > div {
          font-weight: 600;
          font-size: 20px;
        }
        > span {
          color: #000;

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .Section {
        height: 190px;
        border: 1px solid #ededed;
        border-radius: 8px;
        padding: 12px 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.noPadding {
          padding: 0;
        }

        & > img {
          height: 100%;
          width: 80%;
          object-fit: cover;
        }

        .ImageidContainer {
          position: relative;
          border-radius: 8px;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          .loadingWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(4.5px);
            > div {
              display: flex;
              flex-direction: column;
              gap: 4px;
              > span {
                color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

                /* Ception 2/Regular */
                font-family: Poppins;
                font-size: 12px;
                // font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
              }
            }
          }

          & > img {
            object-fit: cover;
            max-height: 100%;
            border-radius: 8px;
          }

          .Footer {
            position: absolute;
            bottom: 0;
            border-radius: 8px;
            display: flex;
            color: $NEUTRAL_10;
            width: 100%;
            height: 30%;
            align-items: center;
            justify-content: center;
            background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
          }
        }

        .DropdownSection {
          border: 1px dashed $PRIMARY_MAIN;
          height: 100%;
          border-radius: 8px;
          background-color: $NEUTRAL_20;
          color: $PRIMARY_MAIN;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 8px;
          width: 100%;
        }
      }
    }

    .NonBreak {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .Description {
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
    }

    .TextNeutral {
      font-weight: 400;
      color: $NEUTRAL_60;
      // margin-top: 0 !important;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    }
    .locationTextBox {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
    }

    .processText {
      color: var(--Primary-Main, #1571de);

      /* Text M/Regular */
      // font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .locationBox {
      display: flex;
      flex-direction: column;

      border-radius: 8px;
      border-right: 1px solid var(--Neutral-Stroke, #ededed);
      border-bottom: 1px solid var(--Neutral-Stroke, #ededed);
      border-left: 1px solid var(--Neutral-Stroke, #ededed);
      background: var(--Neutral-10, #fff);
      position: relative;
      .blueBackground {
        width: 100%;
        position: relative;
        top: 0;
        background: var(--Primary-Main, #1571de);
        height: 4px;
        border-radius: 8px 8px 0px 0px;
      }
    }

    .FullSectionWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .LocationWrapper {
        display: flex;
        // border: 1px solid #ededed;
        // padding: 16px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
      }
    }
  }

  .DisabledButton {
    background-color: $NEUTRAL_30 !important;
    opacity: 0.6;
  }

  .longLat {
    color: var(--Neutral-100, #0a0a0a);

    /* Text M/Regular */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .showOnMobile {
    display: none !important;
  }
}

@media (width <=768px) {
  .Container {
    .showOnMobile {
      display: block !important;
    }

    .hideOnMobile {
      display: none !important;
    }

    .ContractSignContainer {
      padding: 16px;
      // width: 100vw !important;

      .SectionWrapper {
        display: flex;
        flex-direction: column;
        // gap: 16px;c
      }
    }
  }
}
