.bodyList {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  // padding-right: 0px;
}

.loadingData {
  // flex-grow: 1;
  height: 100%;
  // min-height: 500px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyData {
  // flex-grow: 1;
  height: 100vh;
  // min-height: 500px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  .boxEmptyText {
    display: flex;
    flex-direction: column;
    align-items: center;
    .descWarn {
      text-align: center;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #9e9e9e;
    }
    .warn {
      text-align: center;
      // font-family: Poppins;
      color: #000;
      font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.1px;
    }
  }
}

.list {
  width: 100%;
  padding: 8px 16px 8px 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-30, #ededed);
  background: var(--neutral-10, #fff);
  display: flex;
  //   flex-direction: ;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .left {
    .desc {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .addressBox {
        display: flex;
        align-items: center;
        gap: 4px;
        .addressText {
          color: var(--primary-main, #1571de);

          /* Caption 1/Reguler */
          //   font-family: Poppins;
          font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
      .job {
        color: #000;

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
      .title {
        color: var(--neutral-60, #9e9e9e);

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
      .salaryBox{
        display: flex;
        gap: 4px;
        align-items: center;
  
        font-size: 12px;
        font-weight: 400;
        color: #404040;
      } 
    }
    display: flex;
    align-items: center;
    gap: 16px;
    .imageBox {
      width: 37px;
      height: 66px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 8px;
    .buttonApply {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      border-radius: 8px;
      background: var(--primary-main, #1571de);
      color: #fff;
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .buttonDetail {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--neutral-100, #0a0a0a);
      background: var(--neutral-10, #fff);
      color: var(--neutral-100, #0a0a0a);
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

.placement {
  display: flex;
  align-items: center;
  gap: 4px;
  > span {
    color: var(--primary-main, #1571de);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .slice {
    font-weight: 600;
  }
}

@media screen and (max-width: 670px) {
  .list {
    flex-direction: column;
    align-items: flex-start;
    .right {
      width: 100%;
      .buttonDetail {
        width: 100%;
        padding: 8px;
      }
      .buttonApply {
        width: 100%;
        padding: 8px;
      }
    }
  }
}
