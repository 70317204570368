@import "../../../../theme/variables.scss";
.containerError {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
}
.textError {
  display: flex;
  align-items: center;
  gap: 4px;
  display: flex;
  align-items: center;
  padding: 12px 12px;
  border-radius: 32px;
  background: #ffe0e0;
  color: #d90707;
  font-feature-settings: "pwid" on;
  text-align: center;

  /* Body/Body 2 */
  //   font-family: Noto Sans;
  font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.errorIcon {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .containerError {
    align-items: flex-start;
  }
}
