@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .container {
    width: 100%;
    display: flex;
    &.error {
      border: 1px solid red;
      border-radius: 8px;
    }

    .adornment {
      background-color: $NEUTRAL_40;
      border-radius: 8px 0 0 8px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      color: $NEUTRAL_60;
    }

    .input {
      width: 100%;
      padding: 12px;
      border-radius: 0 8px 8px 0;
      border: 1px solid $NEUTRAL_40;
      transition: 0.2s;

      &:disabled {
        outline: none !important;
        border-color: $NEUTRAL_40;
        border-width: 1px;
        border-style: solid;
        background-color: $NEUTRAL_30;
        color: $NEUTRAL_60;
    
      }
    }

    .input:focus {
      outline: none !important;
      border-color: $PRIMARY_MAIN;
      border-width: 1px;
      border-style: solid;
    }
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $DANGER_MAIN;

    &:disabled{
      color: $NEUTRAL_60;
    }
  }
}
